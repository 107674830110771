<template>
  <div class="w-full">
    <div class="field">
      <SearchableSelectList
        id="competence-model-select"
        v-model:selected="initialCompetenceModel"
        :options="competenceModelOptions"
        value-prop="id"
        secondary-label-prop="organisationName"
        class="ph-no-capture"
        :disabled="!isEditable"
        :allow-reset="false"
        :label="$t('components.assessment.competence_model_form.select.template')"
        @update:selected="onPreviewCompetenceModelChanged($event ? $event.id : $event)"
        @blur="resetCompetenceModelSelection"
      />
    </div>
  </div>
</template>

<script>
import { competenceModelTemplatePath } from '@/util/url-helpers';
import SearchableSelectList from '@/components/generic/SearchableSelectList/SearchableSelectList.vue';
import useCompetenceModelOptions from '@/composables/competenceModelOptions';
import Axios from 'axios';

export default {
  name: 'AssessmentCompetenceModelFormSelect',
  components: {
    SearchableSelectList,
  },
  props: {
    assessmentId: {
      type: [Number, String],
      required: true,
    },
    competenceModelData: {
      type: Object,
      default: () => ({}),
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
    availableCompetenceModels: {
      type: Array,
      required: true,
    },
  },
  emits: ['update:competenceModelData', 'change'],
  setup(props) {
    const {
      competenceModelOptions,
      initialCompetenceModel,
    } = useCompetenceModelOptions(props.availableCompetenceModels, props.competenceModelData?.id);

    return {
      competenceModelOptions,
      initialCompetenceModel,
    };
  },
  data() {
    if (this.competenceModelData && !('scale' in this.competenceModelData)) { // reload missing data
      this.onPreviewCompetenceModelChanged(this.competenceModelData.id);
    }
    return {
      competenceModel: { ...this.competenceModelData },
      selectedCompetenceModelId: this.competenceModelData?.id,
      saving: false,
      showChangeWarningModal: false,
    };
  },
  watch: {
    competenceModelData(data) {
      this.competenceModel = { ...data };
      this.initialCompetenceModel = this.competenceModel;
    },
    competenceModel(competenceModel) {
      this.selectedCompetenceModelId = competenceModel.id;
    },
  },
  methods: {
    onPreviewCompetenceModelChanged(newCompetenceModelId) {
      if (newCompetenceModelId) {
        Axios.get(competenceModelTemplatePath(newCompetenceModelId))
          .then((response) => {
            this.$emit('update:competenceModelData', response.data);
          });
      }
    },
    resetCompetenceModelSelection() {
      if (!this.selectedCompetenceModelId) {
        this.selectedCompetenceModelId = this.competenceModel.id;
      }
    },
  },
};
</script>
