<template>
  <BaseButton
    :is-primary="isPrimary"
    :icon="icon"
    :leading-icon="leadingIcon"
    :is-disabled="isDisabled"
    @click="openFileExplorer"
  >
    {{ buttonText || $t("defaults.add") }}
  </BaseButton>
  <input
    v-if="multiFileUpload"
    ref="filesInputRef"
    multiple
    type="file"
    :accept="acceptedMimeTypes"
    :disabled="false"
    class="hidden"
    @change="onFileUpload($event.target)"
  >
  <input
    v-else
    ref="filesInputRef"
    type="file"
    :accept="acceptedMimeTypes"
    :disabled="false"
    class="hidden"
    @change="onFileUpload($event.target)"
  >
</template>

<script>

import { defineComponent, ref } from 'vue';
import { useReportTemplatesStore } from './stores/reportTemplatesStore';

export default defineComponent({
  name: 'UploadButton',
  components: {
  },
  props: {
    acceptedMimeTypes: {
      type: Array,
      required: false,
      default: null,
    },
    maxFileSize: {
      type: Number,
      default: 1024 * 1024 * 200, // 200MB
    },
    multiFileUpload: {
      type: Boolean,
      default: false,
    },
    isPrimary: {
      type: Boolean,
      default: true,
    },
    buttonText: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    leadingIcon: {
      type: Boolean,
      default: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['uploaded-files', 'start-uploading-files'],
  setup(props, { emit }) {
    const store = useReportTemplatesStore();

    const filesInputRef = ref(null);

    const openFileExplorer = () => {
      filesInputRef.value.click();
    };

    const onFileUpload = async ({ files }) => {
      if (files.length) {
        emit('start-uploading-files', files);
        const uploadedFiles = await store.uploadSelectedFile(
          files,
          {
            acceptedMimeTypes: props.acceptedMimeTypes.value,
            maxFileSize: props.maxFileSize.value,
          },
        );
        if (uploadedFiles.length > 0) {
          emit('uploaded-files', uploadedFiles);
        }
        filesInputRef.value.files = new DataTransfer().files;
      }
    };

    return {
      filesInputRef,

      openFileExplorer,
      onFileUpload,
    };
  },
});
</script>
