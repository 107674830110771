<template
  class="flex"
>
  <Modal
    :open="open"
    :hide-overflow="false"
    @click-outside="$emit('close')"
  >
    <div class="bg-white py-2 px-3 sm:p-6 sm:max-w-lg rounded-lg">
      <div class="sm:flex sm:items-start">
        <div
          v-if="assessmentStatus === 'active'"
          class="mx-auto shrink-0 flex items-center justify-center
           rounded-full bg-red-100 h-12 w-12 sm:h-10 sm:w-10 sm:mx-0"
        >
          <ExclamationIcon class="h-6 w-6 text-orange-600" />
        </div>
        <div class="text-center space-y-4 sm:mt-0 sm:ml-4 sm:text-left">
          <div class="space-y-2">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              {{ $t("components.observers.replace_observer") }}
            </h3>

            <div v-if="assessmentStatus === 'active'">
              <p class="text-sm text-gray-500">
                {{ $t("components.observers.replace_observer_warning_text") }}
              </p>
            </div>
            <div v-else>
              <p class="text-sm text-gray-500">
                {{ $t("components.observers.replace_observer_info_text") }}
              </p>
            </div>
          </div>
          <div>
            <div class="space-y-4">
              <form
                id="edit-observer-form"
                :action="actionUrl"
                accept-charset="UTF-8"

                method="post"
                enctype="multipart/form-data"
                @submit="submitting = true"
              >
                <input
                  type="hidden"
                  name="_method"
                  value="patch"
                >
                <input
                  type="hidden"
                  name="authenticity_token"
                  :value="$csrfToken"
                >
                <input
                  type="hidden"
                  name="assessment_id"
                  :value="observerAssignment.assessmentId"
                >
                <div class="mb-4">
                  <SearchableSelectList
                    id="selected-user-select-list"
                    v-model:selected="selectedUser"
                    :options="availableUsers"
                    value-prop="id"
                    label-prop="name"
                    secondary-label-prop="email"
                    name="observer_assignment[user_id]"
                    :allow-reset="false"
                    :disabled="assessmentCompleted"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-row justify-end">
        <footer class="flex justify-end flex-row space-x-4">
          <BaseButton
            type="button"
            :is-primary="false"
            @click="$emit('close')"
          >
            {{ $t('defaults.cancel') }}
          </BaseButton>
          <SaveButton
            v-if="!assessmentCompleted"
            :is-loading="submitting"
            icon=""
            form="edit-observer-form"
          >
            {{ $t('defaults.replace') }}
          </SaveButton>
        </footer>
      </div>
    </div>
  </Modal>
</template>

<script>
import { ref } from 'vue';
import Modal from '@/components/generic/Modal/Modal.vue';
import SearchableSelectList from '@/components/generic/SearchableSelectList/SearchableSelectList.vue';
import { assessmentObserverAssignmentPath, organisationAssessmentObserverAssignmentPath } from '@/util/url-helpers';
import { ExclamationIcon } from '@heroicons/vue/outline';

export default {
  name: 'ObserverAssignmentActionsReplaceModal',
  components: {
    Modal,
    SearchableSelectList,
    ExclamationIcon,
  },
  props: {
    observerAssignment: {
      type: Object,
      required: true,
    },
    organisationId: {
      type: Number,
      required: false,
      default: null,
    },
    assessmentStatus: {
      type: String,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const observer = ref({ ...props.observerAssignment });
    const availableUsers = ref(props.users);
    const selectedUser = ref(observer.value.user);
    const assessmentCompleted = props.assessmentStatus === 'completed';

    const submitting = ref(false);
    const actionUrl = props.organisationId
      ? organisationAssessmentObserverAssignmentPath(
        props.organisationId,
        observer.value.assessmentId,
        observer.value.id,
      )
      : assessmentObserverAssignmentPath(observer.value.assessmentId, observer.value.id);

    const close = () => {
      emit('close');
    };
    return {
      close,
      actionUrl,
      submitting,
      availableUsers,
      selectedUser,
      assessmentCompleted,
    };
  },
};
</script>
