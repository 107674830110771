<template>
  <tr
    class="children:h-20 children:border-b border-t children:py-4 text-sm align-middle file-bg
       hover:bg-gray-50 hover:cursor-pointer"
    :style="{ 'background-size': `${file.pending ? file.progress : 0}%`,
    }"
    @click="onFilenameClick()"
  >
    <td class="pl-6">
      <div class="flex flex-row items-center">
        <div
          v-if="isShareable"
          class="drag-handle cursor-move flex items-center"
        >
          <i
            v-show="!isDisabled"
            class="material-icons-outlined handle cursor-move h-5 w-5 mr-4 text-gray-400"
          >drag_indicator</i>
        </div>

        <div
          class="ph-no-capture relative"
          @click.stop
        >
          <FileName
            v-model="filename"
            :pending="file.pending || !!file.errors"
            :upload-progress="file.progress"
            :disabled="isDisabled"
            :preview="preview"
            @click="onFilenameClick()"
            @mouseover="showPreview = true"
            @mouseleave="showPreview = false"
          />

          <FilePreview
            :show="showPreview"
            :file="file"
          />
        </div>

        <Badge
          v-if="file.category == 'test'"
          color="primary"
        >
          <ClipboardListIcon
            class="h-5 mr-1"
            aria-hidden="true"
          />
          {{ $t('activerecord.models.test.one') }}
        </Badge>

        <Badge
          v-else-if="file.category == 'report'"
          color="blue"
        >
          <DocumentReportIcon
            class="h-5 mr-1"
            aria-hidden="true"
          />
          {{ $t('activerecord.models.reports.one') }}
        </Badge>
      </div>
    </td>

    <template v-if="isShareable && !preview">
      <td>
        <div class="flex justify-center">
          <BaseCheckbox
            :model-value="file.forCandidate"
            :disabled="isDisabled"
            @click.stop
            @update:model-value="updateFile('forCandidate', !file.forCandidate)"
          />
        </div>
      </td>

      <td>
        <div class="flex justify-center">
          <BaseCheckbox
            :model-value="file.forCandidate && file.downloadable"
            :disabled="isDisabled || !file.forCandidate"
            @click.stop
            @update:model-value="updateFile('downloadable', !file.downloadable)"
          />
        </div>
      </td>

      <td>
        <div class="flex justify-center">
          <BaseCheckbox
            :model-value="file.forCandidate && file.textSelectable"
            :disabled="isDisabled || !file.forCandidate || !isSelectableFileType(file.type)"
            @click.stop
            @update:model-value="updateFile('textSelectable', !file.textSelectable)"
          />
        </div>
      </td>
    </template>

    <td
      v-if="!preview"
      class="text-center pr-2"
    >
      <FileActions
        :file="file"
        :disabled="isDisabled"
        @click.stop
        @download="downloadFile"
        @remove="deleteFile"
      />
    </td>
  </tr>
</template>

<script>
import { defineComponent, toRefs, computed, ref } from 'vue';
import BaseCheckbox from '@/components/generic/BaseCheckbox/BaseCheckbox.vue';
import download from '@/util/download';
import Badge from '@/components/generic/Badge/Badge.vue';
import { ClipboardListIcon, DocumentReportIcon } from '@heroicons/vue/outline';
import FilePreview from './FilePreview/FilePreview.vue';
import FileName from './FileName/FileName.vue';
import FileActions from './FileActions/FileActions.vue';
import { useMultiFileUploadStore } from '../../multiFileUploadStore';

export default defineComponent({
  components: {
    BaseCheckbox,
    FilePreview,
    FileName,
    FileActions,
    Badge,
    ClipboardListIcon,
    DocumentReportIcon,
  },
  props: {
    file: {
      type: Object,
      required: true,
    },
    isShareable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['filename-click'],
  setup(props, { emit }) {
    const { file, disabled } = toRefs(props);

    const store = useMultiFileUploadStore();

    const isDisabled = computed(() => disabled.value || file.value.pending || !!file.value.errors?.length);
    const showPreview = ref(false);

    // File CRUD

    const updateFile = (prop, value) => {
      store.updateFile({ file: file.value, data: { [prop]: value } });
    };

    const deleteFile = () => {
      store.deleteFileWithConfirmation(file.value);
    };

    const filename = computed({
      get() {
        return file.value.filename;
      },
      set(newFilename) {
        updateFile('filename', newFilename);
      },
    });

    return {
      showPreview,
      isDisabled,
      filename,
      updateFile,
      deleteFile,
      downloadFile() {
        download({
          path: file.value.url,
          filename: file.value.filename,
        });
      },
      onFilenameClick() {
        emit('filename-click', { file: file.value });
      },
      isSelectableFileType(fileType) { return store.isSelectableFileType(fileType); },
    };
  },
});
</script>
<style scoped>
.file-bg{
  background-image: linear-gradient(to right, var(--color-primary-50) 0%, var(--color-primary-50)100%);
  background-repeat: no-repeat;
}
</style>
