<template>
  <div
    ref="viewport"
    class="sticky z-30 top-0 w-full"
  >
    <div
      v-if="showBackButton"
      class="m-auto w-11/12 sm:w-10/12 2xl:w-9/12"
    >
      <div
        class="flex items-center p-2 -ml-3 mt-2 -mb-2 gap-px w-min h-min
      cursor-pointer text-lg text-gray-500 hover:text-gray-700"
        @click="goBack"
      >
        <ChevronLeftIcon
          class="flex shrink-0 h-5 w-5"
          aria-hidden="true"
        />
        <span class="sr-only">Back</span>
        <p>
          {{ $t("defaults.back") }}
        </p>
      </div>
    </div>
    <div
      v-else
      class="w-full bg-white border-b border-gray-200 flex items-center pr-4 sm:pr-6 lg:pr-8"
    >
      <nav
        aria-label="Breadcrumb"
      >
        <ol class="w-full mx-auto pl-4 pr-2 flex space-x-4 sm:pl-6 sm:pr-3 lg:pl-8 lg:pr-4">
          <li class="flex">
            <div class="flex items-center">
              <a
                href="/assessments"
                class="text-gray-400 hover:text-gray-500"
              >
                <HomeIcon
                  class="shrink-0 h-5 w-5"
                  aria-hidden="true"
                />
                <span class="sr-only">Home</span>
              </a>
            </div>
          </li>
          <li
            v-for="(page, index) in pages"
            :key="page.name"
            class="flex"
          >
            <div class="flex items-center">
              <svg
                class="shrink-0 w-6 h-full text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <a
                :href="page.href"
                class="ph-no-capture ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 line-clamp-2"
                :aria-current="index === pages.length - 1 ? 'page' : undefined"
              >{{ page.name }}</a>
            </div>
          </li>
        </ol>
      </nav>
      <AssessmentStatusBadge
        v-if="assessmentStatus"
        :status="assessmentStatus"
      />
    </div>
  </div>
</template>

<script>
import { ChevronLeftIcon, HomeIcon } from '@heroicons/vue/solid';
import { onMounted, onUnmounted, ref } from 'vue';
import AssessmentStatusBadge from './Assessments/AssessmentStatusBadge/AssessmentStatusBadge.vue';

export default {
  components: {
    ChevronLeftIcon,
    HomeIcon,
    AssessmentStatusBadge,
  },
  props: {
    /**
     * { name: string, href: string }[]
     */
    pages: {
      type: Array,
      required: true,
    },
    assessmentStatus: {
      type: String,
      required: false,
      default: null,
    },
    navigationBackPath: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const showBackButton = ref(false);

    const updateViewportWidth = () => {
      showBackButton.value = props.navigationBackPath && window.innerWidth <= 1280;
    };

    onMounted(() => {
      if (props.navigationBackPath) {
        window.addEventListener('resize', updateViewportWidth);
        updateViewportWidth();
      }
    });

    onUnmounted(() => {
      if (props.navigationBackPath) {
        window.removeEventListener('resize', updateViewportWidth);
      }
    });

    const goBack = () => {
      Turbo.visit(props.navigationBackPath);
    };

    return {
      showBackButton,
      goBack,
    };
  },
};
</script>
