<template>
  <div>
    <div
      v-for="(featureEnabled, feature) in features"
      :key="feature"
      class="flex items-center justify-between mb-8"
    >
      <div>
        <div class="flex flex-row items-center pb-2">
          <div class="flex flex-row items-center">
            <h2 class="text-xl text-gray-900 font-medium mr-3">
              {{ $t(`components.assessment_features_form.${feature}.title`) }}
            </h2>
            <beta-feature-badge
              v-if="featureInBeta.hasOwnProperty(feature)"
              class="mr-3"
            />
          </div>
          <div
            v-if="featureMinAppVersion.hasOwnProperty(feature)"
            class="flex flex-row items-center text-blue-500"
          >
            <i class="material-icons-outlined mr-1">info</i>
            <!-- eslint-disable-next-line vue/no-v-html, max-len -->
            <p v-html="$t('components.assessment_features_form.requires_app_version', { version: featureMinAppVersion[feature] })" />
          </div>
        </div>
        <!-- eslint-disable vue/no-v-html -->
        <p
          class="text-base text-gray-500"
          v-html="$t(`components.assessment_features_form.${feature}.description`)"
        />
        <!-- eslint-enable vue/no-v-html -->
      </div>

      <div
        :key="`${feature}-${featureEnabled}`"
        class="flex"
      >
        <slot
          name="toggle"
          v-bind="slotProps(feature)"
        >
          <Toggle
            :disabled="!isEditable"
            :model-value="featureEnabled"
            :qa-class="`${feature}-toggle`"
            @update:model-value="updateFeature(feature)"
          />
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import { assessmentFeaturesPath } from '@/util/url-helpers';
import BetaFeatureBadge from '@/components/generic/FeatureBadges/BetaFeatureBadge.vue';
import Toggle from '@/components/generic/Toggle/Toggle.vue';
import Axios from 'axios';

export default {
  name: 'AssessmentFeaturesForm',
  components: {
    BetaFeatureBadge,
    Toggle,
  },
  props: {
    initialFeatures: {
      type: Object,
      required: true,
    },
    assessmentId: {
      type: [Number, String],
      default: '',
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:initialFeatures'],
  data() {
    return {
      features: this.initialFeatures,
      featureMinAppVersion: {
      },
      featureInBeta: {},
    };
  },
  watch: {
    initialFeatures(newFeatures) {
      this.features = { ...newFeatures };
    },
  },
  methods: {
    toggleFeature(feature) {
      this.features[feature] = !this.features[feature];
      this.$emit('update:initialFeatures', this.features);
    },
    updateFeature(feature) {
      const data = {
        assessment_features: {
          [feature]: !this.features[feature],
        },
      };

      Axios.patch(assessmentFeaturesPath(this.assessmentId), data)
        .then(() => {
          this.toggleFeature(feature);
          this.$toast.success(this.$t('components.assessment_features_form.successfully_updated_feature'));
        })
        .catch(() => {
          this.$toast.error(this.$t('components.assessment_features_form.failed_to_update_feature'));
        });
    },

    slotProps(feature) {
      return {
        feature,
        featureEnabled: this.features[feature],
        toggleFeature: this.toggleFeature,
      };
    },
  },
};
</script>
