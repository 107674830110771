<template>
  <div
    class="relative w-full max-w-full overflow-y-visible 2xl:overflow-visible"
    :class="bottomPaddingClass"
  >
    <div
      ref="dragContainer"
      class="flex w-full h-full overflow-x-auto 2xl:overflow-visible"
      @dragover.prevent="onDragOver"
      @dragenter.prevent="onDragOver"
      @dragleave.prevent="onDragLeave"
      @drop.prevent="onFilesDrop"
    >
      <table
        :class="['table-auto w-full text-left', hasNoFiles ? 'min-h-40' : '']"
      >
        <colgroup>
          <col
            span="1"
            :class="[hasShareableFiles ? 'w-6/12' : 'w-full']"
          >
          <col
            v-if="hasShareableFiles"
            span="3"
            class="w-2/12"
          >
          <col
            span="1"
            class="w-auto"
          >
        </colgroup>

        <thead class="text-sm uppercase text-gray-500 border-t border-gray-300">
          <tr class="children:py-3">
            <th class="pl-6">
              {{ $t("components.multi_file_upload.file_list.document") }}
            </th>
            <template v-if="hasShareableFiles">
              <th class="text-center min-w-40">
                {{ $t("activerecord.attributes.shareable_file.for_candidate") }}
              </th>
              <th class="text-center min-w-40">
                {{ $t("activerecord.attributes.shareable_file.downloadable") }}
              </th>
              <th class="text-center min-w-40">
                {{ $t("activerecord.attributes.shareable_file.text_selectable") }}
              </th>
            </template>
            <th />
          </tr>
        </thead>

        <Draggable
          v-model="files"
          item-key="id"
          :disabled="disabled"
          tag="tbody"
          ghost-class="dragging"
          handle=".drag-handle"
          drag-class="bg-white"
          :animation="200"
          :move="checkMove"
          @start="onFileDragStart"
          @end="onFileMove"
        >
          <template #item="{ index }">
            <File
              :file="files[index]"
              :disabled="disabled"
              :is-shareable="hasShareableFiles"
              :class="[dragOver ? 'invisible' : 'visible']"
              :preview="preview"
              @filename-click="onFilenameClick(files[index])"
            />
          </template>
        </Draggable>
      </table>

      <!-- Dropzone -->
      <div
        v-if="!disabled && (dragOver || hasNoFiles)"
        class="absolute inset-x-0 bottom-0 top-14 z-10 bg-white"
      >
        <div
          :class="[`min-h-40 flex justify-center items-center px-6 pt-5 pb-6
          border-2 border-dashed border-gray-300 bg-white rounded-md mx-6`, dragOver ? 'border-4' : 'border-2' ]"
        >
          <div class="space-y-1 flex flex-col items-center">
            <svg
              class="mx-auto h-12 w-12 text-gray-400"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <!-- eslint-disable max-len -->
              <path
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <!-- eslint-enable max-len -->
            </svg>
            <div class="flex text-sm text-gray-600">
              <div
                :class="[
                  'relative rounded-md font-medium select-none',
                  'focus-within:outline-none focus-within:ring-offset-2 focus-within:ring-primary-500',
                  disabled
                    ? 'text-gray-700'
                    : 'cursor-pointer text-primary-600 hover:text-primary-500 focus-within:ring-2',
                ]"
              >
                <button
                  role="button"
                  @click.prevent="onUploadFileTextClicked"
                >
                  {{
                    $t("components.multi_file_upload.upload_files")
                  }}
                </button>
              </div>
              <p class="pl-1">
                {{ $t("components.single_file_upload.or_drag_and_drop") }}
              </p>
            </div>
            <p class="text-xs text-gray-500">
              {{ fileTypesAndSizeLimit }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent, ref, toRefs, computed,
} from 'vue';
import Draggable from 'vuedraggable';
import { storeToRefs } from 'pinia';
import File from './File/File.vue';
import { useMultiFileUploadStore } from '../multiFileUploadStore';

export default defineComponent({
  components: {
    Draggable,
    File,
  },
  props: {
    hasShareableFiles: {
      type: Boolean,
      default: false,
    },
    fileTypesAndSizeLimit: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['filename-clicked', 'upload-file-clicked'],
  setup(props, { emit }) {
    const { disabled } = toRefs(props);

    // Get Store

    const store = useMultiFileUploadStore();
    const { files } = storeToRefs(store);

    // Upload button in empty state

    const onUploadFileTextClicked = () => emit('upload-file-clicked');

    // Styling contraints

    const hasNoFiles = computed(() => files.value.length === 0);
    const bottomPaddingClass = computed(() => (files.value.length < 2 ? 'pb-24' : ''));

    // Drag and Drop

    const checkMove = () => !disabled.value;

    const dragging = ref(false);

    const onFileDragStart = () => {
      dragging.value = true;
    };

    const onFileMove = ({ newIndex, oldIndex }) => {
      dragging.value = false;
      const position = newIndex + 1;
      if (newIndex !== oldIndex) {
        const file = files.value[newIndex];
        store.updateFile({ file, data: { position } });
      }
    };

    const dragContainer = ref(null);
    const dragOver = ref(false);
    const onDragOver = () => {
      if (dragging.value) return;

      dragOver.value = true;
    };
    const onDragLeave = ({ relatedTarget }) => {
      if (dragContainer.value.contains(relatedTarget)) return;

      dragOver.value = false;
    };

    const onFilesDrop = ({ dataTransfer }) => {
      const { files: droppedFiles } = dataTransfer;
      if (droppedFiles.length) {
        store.uploadSelectedFiles(droppedFiles);
      }
      dragOver.value = false;
    };

    return {
      files,
      onUploadFileTextClicked,

      hasNoFiles,
      bottomPaddingClass,

      checkMove,
      onFileDragStart,
      onFileMove,

      dragContainer,
      dragOver,
      onDragOver,
      onDragLeave,
      onFilesDrop,

      onFilenameClick(file) {
        emit('filename-clicked', { file });
      },

    };
  },
});
</script>

<style lang="scss" scoped>
th {
  @apply font-medium #{!important};
}
</style>
