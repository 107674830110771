<template>
  <Sidebar>
    <a
      v-for="item in navigation"
      :key="item.name"
      :href="item.href"
      :class="[
        item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
        'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
        item.qaClass
      ]"
    >
      <component
        :is="item.icon"
        :class="[
          item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
          'mr-3 shrink-0 h-6 w-6'
        ]"
        aria-hidden="true"
      />
      <span class="flex-1">
        {{ item.name }}
      </span>
      <span
        v-if="item.count"
        :class="[
          item.current ? 'bg-white' : 'bg-gray-100 group-hover:bg-gray-200',
          'ml-3 inline-block py-0.5 px-3 text-xs font-medium rounded-full'
        ]"
      >
        {{ item.count }}
      </span>
      <BetaFeatureBadge
        v-if="item.isBeta"
        class="ml-2"
      />
    </a>
  </Sidebar>
</template>

<script>
import {
  CalendarIcon, CheckIcon, ClipboardListIcon, HomeIcon, InboxIcon, NewspaperIcon, PuzzleIcon, UsersIcon, UserGroupIcon,
} from '@heroicons/vue/outline';
import Sidebar from '@/components/Sidebar.vue';
import { useI18n } from 'vue-i18n';
import {
  editAssessmentPath, assessmentCandidatesPath, assessmentObserverAssignmentsPath, assessmentCompetenceModelPath,
  assessmentReportTemplatesPath, assessmentScheduleEditPath, assessmentGeneralDocumentsPath,
  assessmentFeaturesPath, assessmentStatusPath,
  editOrganisationAssessmentPath, organisationAssessmentCandidatesPath, organisationAssessmentObserverAssignmentsPath,
  organisationAssessmentCompetenceModelPath, organisationAssessmentReportTemplatesPath,
  organisationAssessmentScheduleEditPath, organisationAssessmentGeneralDocumentsPath,
  organisationAssessmentFeaturesPath, organisationAssessmentStatusPath,
} from '@/util/url-helpers';
import BetaFeatureBadge from '@/components/generic/FeatureBadges/BetaFeatureBadge.vue';

export default {
  components: {
    Sidebar,
    BetaFeatureBadge,
  },
  props: {
    assessmentId: {
      type: [Number, String],
      required: true,
    },
    organisationId: {
      type: [Number, String],
      required: false,
      default: null,
    },
    countStatistics: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { assessmentId, organisationId, countStatistics } = props;

    const { t } = useI18n();

    const editPath = props.organisationId
      ? editOrganisationAssessmentPath(organisationId, assessmentId) : editAssessmentPath(assessmentId);

    const candidatesPath = props.organisationId
      ? organisationAssessmentCandidatesPath(organisationId, assessmentId) : assessmentCandidatesPath(assessmentId);

    const observerAssignmentsPath = props.organisationId
      ? organisationAssessmentObserverAssignmentsPath(organisationId, assessmentId)
      : assessmentObserverAssignmentsPath(assessmentId);

    const competenceModelPath = props.organisationId
      ? organisationAssessmentCompetenceModelPath(organisationId, assessmentId)
      : assessmentCompetenceModelPath(assessmentId);

    const reportTemplatesPath = props.organisationId
      ? organisationAssessmentReportTemplatesPath(organisationId, assessmentId)
      : assessmentReportTemplatesPath(assessmentId);

    const schedulePath = props.organisationId
      ? organisationAssessmentScheduleEditPath(organisationId, assessmentId)
      : assessmentScheduleEditPath(assessmentId);

    const generalDocumentsPath = props.organisationId
      ? organisationAssessmentGeneralDocumentsPath(organisationId, assessmentId)
      : assessmentGeneralDocumentsPath(assessmentId);

    const featuresPath = props.organisationId
      ? organisationAssessmentFeaturesPath(organisationId, assessmentId)
      : assessmentFeaturesPath(assessmentId);

    const statusPath = props.organisationId
      ? organisationAssessmentStatusPath(organisationId, assessmentId) : assessmentStatusPath(assessmentId);

    const navigation = [
      {
        name: t('activerecord.models.assessment.one'),
        icon: HomeIcon,
        href: editPath,
        current: window.location.pathname === editPath,
        qaClass: 'qa-sidebar-assessment',
      },
      {
        name: t('activerecord.models.candidate.other'),
        icon: UsersIcon,
        href: candidatesPath,
        count: countStatistics.candidates || 0,
        current: window.location.pathname.startsWith(candidatesPath),
        qaClass: 'qa-sidebar-candidates',
      },
      {
        name: t('activerecord.models.observer_assignment.other'),
        icon: UserGroupIcon,
        href: observerAssignmentsPath,
        count: countStatistics.observerAssignments || 0,
        current: window.location.pathname.startsWith(observerAssignmentsPath),
        qaClass: 'qa-sidebar-observers',
      },
      {
        name: t('activerecord.models.competence_model.one'),
        icon: ClipboardListIcon,
        href: competenceModelPath,
        current: window.location.pathname === competenceModelPath,
        qaClass: 'qa-sidebar-competence-model',
      },
      {
        name: t('activerecord.models.report_templates.other'),
        icon: NewspaperIcon,
        href: reportTemplatesPath,
        current: window.location.pathname === reportTemplatesPath,
        isBeta: true,
        qaClass: 'qa-sidebar-report-templates',
      },
      {
        name: t('activerecord.models.schedule'),
        icon: CalendarIcon,
        href: schedulePath,
        current: window.location.pathname === schedulePath,
        qaClass: 'qa-sidebar-schedule',
      },
      {
        name: t('activerecord.attributes.assessment.general_documents'),
        icon: InboxIcon,
        href: generalDocumentsPath,
        count: countStatistics.documents || 0,
        current: window.location.pathname === generalDocumentsPath,
        qaClass: 'qa-sidebar-documents',
      },
      {
        name: t('activerecord.attributes.assessment.assessment_features'),
        icon: PuzzleIcon,
        href: featuresPath,
        current: window.location.pathname === featuresPath,
        qaClass: 'qa-sidebar-features',
      },
      {
        name: t('activerecord.attributes.assessment.status'),
        icon: CheckIcon,
        href: statusPath,
        current: window.location.pathname === statusPath,
        qaClass: 'qa-sidebar-status',
      },
    ];

    return {
      navigation,
    };
  },
};
</script>
