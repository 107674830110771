<template>
  <BaseTableActions>
    <template #menu-items>
      <LinkDropdownItem
        :href="editPath"
        class="flex flex-row"
      >
        <div class="mr-4">
          <PencilIcon
            class="h-5 w-5 text-gray-500"
            aria-hidden="true"
          />
        </div>
        <span class="text-gray-700">{{ $t('defaults.edit') }}</span>
      </LinkDropdownItem>

      <LinkDropdownItem
        v-if="assessmentEditable"
        :href="deletePath"
        method="delete"
        :remote="true"
        :confirm="confirmDeletionMessage"
        :challenge="candidate.name"
        class="flex flex-row border-t border-gray-300"
      >
        <div class="mr-4">
          <TrashIcon
            class="h-5 w-5 text-red-500"
            aria-hidden="true"
          />
        </div>
        <span class="text-red-700">{{ $t('defaults.delete') }}</span>
      </LinkDropdownItem>
    </template>
  </BaseTableActions>
</template>

<script>
import { defineComponent, toRefs, computed } from 'vue';
import { PencilIcon, TrashIcon } from '@heroicons/vue/solid';
import LinkDropdownItem from '@/components/generic/Dropdown/LinkDropdownItem/LinkDropdownItem.vue';
import {
  assessmentCandidatePath, editAssessmentCandidatesPath,
  editOrganisationAssessmentCandidatesPath, organisationAssessmentCandidatePath,
} from '@/util/url-helpers';
import { useI18n } from '@/plugins/i18n';
import BaseTableActions from '@/components/generic/BaseTable/BaseTableActions/BaseTableActions.vue';

export default defineComponent({
  components: {
    PencilIcon,
    TrashIcon,
    LinkDropdownItem,
    BaseTableActions,
  },
  props: {
    assessmentEditable: {
      type: Boolean,
      required: true,
    },
    assessmentActive: {
      type: Boolean,
      required: false,
      default: false,
    },
    candidate: {
      type: Object,
      required: true,
    },
    organisationId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const { candidate, assessmentActive } = toRefs(props);
    const { t } = useI18n();

    const editPath = props.organisationId
      ? editOrganisationAssessmentCandidatesPath(props.organisationId, candidate.value.assessmentId, candidate.value)
      : editAssessmentCandidatesPath(candidate.value.assessmentId, candidate.value);

    const deletePath = props.organisationId
      ? organisationAssessmentCandidatePath(props.organisationId, candidate.value.assessmentId, candidate.value)
      : assessmentCandidatePath(candidate.value.assessmentId, candidate.value);

    const confirmDeletionMessage = computed(() => (assessmentActive.value ? t(
      'components.candidates.candidates_table.candidate_actions.do_you_want_to_delete_with_ratings',
      { name: candidate.value.name },
    ) : t(
      'components.candidates.candidates_table.candidate_actions.do_you_want_to_delete',
      { name: candidate.value.name },
    )));

    return {
      confirmDeletionMessage,
      assessmentCandidatePath,
      editPath,
      deletePath,
    };
  },
});
</script>
