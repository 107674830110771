<template>
  <BaseTableActions>
    <template #menu-items>
      <LinkDropdownItem
        :href="editPath"
        class="flex flex-row"
      >
        <div class="mr-4">
          <PencilIcon
            class="h-5 w-5 text-gray-500"
            aria-hidden="true"
          />
        </div>
        <span class="text-gray-700">{{ $t('defaults.edit') }}</span>
      </LinkDropdownItem>

      <LinkDropdownItem
        v-if="activeAssessment"
        :href="completeAssessmentPath(assessment)"
        method="put"
        :remote="true"
        :confirm="confirmCompletionMessage"
        :challenge="assessment.name"
        class="flex flex-row"
      >
        <div class="mr-4">
          <StopIcon
            class="h-5 w-5 text-gray-500"
            aria-hidden="true"
          />
        </div>
        <span class="text-gray-700">
          {{ $t('components.assessments.assessments_table.assessment_actions.complete') }}
        </span>
      </LinkDropdownItem>

      <LinkDropdownItem
        :href="duplicatePath"
        method="post"
        class="flex flex-row"
      >
        <div class="mr-4">
          <DuplicateIcon
            class="h-5 w-5 text-gray-500"
            aria-hidden="true"
          />
        </div>
        <span class="text-gray-700">
          {{ $t('components.assessments.assessments_table.assessment_actions.duplicate') }}
        </span>
      </LinkDropdownItem>

      <LinkDropdownItem
        v-if="completedAssessment"
        :href="insightsArchivePath"
        method="post"
        :remote="true"
        :confirm="confirmInsightsArchivedMessage"
        :info-link="$t('components.assessment_workflow_setting.support_link')"
        :challenge="assessment.name"
        class="flex flex-row border-t border-gray-300"
      >
        <div class="mr-4">
          <Heroicon
            icon="ArchiveIcon"
            class="h-5 w-5 text-red-500"
          />
        </div>
        <span class="text-red-700">
          {{ $t('components.assessments.assessments_table.assessment_actions.insights_archived.label') }}
        </span>
      </LinkDropdownItem>

      <LinkDropdownItem
        v-if="!activeAssessment"
        :href="organisationId ? organisationAssessmentPath(organisationId, assessment) : assessmentPath(assessment)"
        method="delete"
        :confirm="confirmDeletionMessage"
        :challenge="assessment.name"
        :class="['flex flex-row', completedAssessment ? '' : 'border-t border-gray-300']"
      >
        <div class="mr-4">
          <TrashIcon
            class="h-5 w-5 text-red-500"
            aria-hidden="true"
          />
        </div>
        <span class="text-red-700">{{ $t('defaults.delete') }}</span>
      </LinkDropdownItem>
    </template>
  </BaseTableActions>
</template>

<script>
import { defineComponent, toRefs, computed } from 'vue';
import { PencilIcon, StopIcon, DuplicateIcon, TrashIcon } from '@heroicons/vue/solid';
import LinkDropdownItem from '@/components/generic/Dropdown/LinkDropdownItem/LinkDropdownItem.vue';
import {
  assessmentPath, editAssessmentPath, duplicateAssessmentPath,
  insightsArchiveAssessmentPath, completeAssessmentPath,
  organisationAssessmentPath, editOrganisationAssessmentPath, duplicateOrganisationAssessmentPath,
  insightsArchiveOrganisationAssessmentPath,
} from '@/util/url-helpers';
import { useI18n } from '@/plugins/i18n';
import BaseTableActions from '@/components/generic/BaseTable/BaseTableActions/BaseTableActions.vue';
import Heroicon from '@/components/Heroicon.vue';

export default defineComponent({
  components: {
    PencilIcon,
    StopIcon,
    DuplicateIcon,
    TrashIcon,
    Heroicon,
    LinkDropdownItem,
    BaseTableActions,
  },
  props: {
    assessment: {
      type: Object,
      required: true,
    },
    organisationId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const { assessment } = toRefs(props);
    const { t } = useI18n();

    const activeAssessment = assessment.value.status === 'active';
    const completedAssessment = assessment.value.status === 'completed';

    const editPath = props.organisationId
      ? editOrganisationAssessmentPath(props.organisationId, assessment.value) : editAssessmentPath(assessment.value);
    const duplicatePath = props.organisationId
      ? duplicateOrganisationAssessmentPath(props.organisationId, assessment.value)
      : duplicateAssessmentPath(assessment.value);
    const insightsArchivePath = props.organisationId
      ? insightsArchiveOrganisationAssessmentPath(props.organisationId, assessment.value)
      : insightsArchiveAssessmentPath(assessment.value);

    const confirmInsightsArchivedMessage = computed(() => t(
      'components.assessments.assessments_table.assessment_actions.insights_archived.confirm',
      { name: assessment.value.name },
    ));

    const confirmDeletionMessage = computed(() => t(
      'components.assessments.assessments_table.assessment_actions.do_you_want_to_delete',
      { name: assessment.value.name },
    ));

    const confirmCompletionMessage = computed(() => t(
      'components.assessments.assessments_table.assessment_actions.complete_confirm',
      { name: assessment.value.name },
    ));

    return {
      confirmInsightsArchivedMessage,
      confirmDeletionMessage,
      confirmCompletionMessage,
      activeAssessment,
      completedAssessment,
      assessmentPath,
      completeAssessmentPath,
      organisationAssessmentPath,
      editPath,
      duplicatePath,
      insightsArchivePath,
    };
  },
});
</script>
