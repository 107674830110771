<template>
  <template v-if="reportTemplatesFeatureEnabled">
    <div class="space-y-6">
      <PageHeading>
        <div class="flex flex-row items-center">
          {{ title }} <BetaFeatureBadge class="ml-2" />
        </div>
        <template #actions>
          <div class="flex flex-col sm:flex-row sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 pr-1">
            <UploadButton
              :button-text="$t('defaults.upload')"
              :accepted-mime-types="acceptedMimeTypes"
              :is-primary="false"
              :is-disabled="!isEditable"
              icon="UploadIcon"
              @uploaded-files="onFileUpload"
              @start-uploading-files="onFileUpload($event, 'pending')"
            />
            <BaseButton
              icon="PlusIcon"
              :is-disabled="!isEditable"
              @click="openReportTemplateSelectionModal"
            >
              {{ $t("defaults.select") }}
            </BaseButton>
          </div>
        </template>
      </PageHeading>
      <div v-if="store.reportTemplates.length > 0">
        <div
          :class="['justify-center sm:justify-start pb-6 gap-5 flex flex-row flex-wrap min-h-fit relative',
                   dragOver ? 'outline-4 outline-primary-500 rounded-xl outline-dashed ' : '']"
          @dragover.prevent="dragOver=true"
          @dragenter.prevent="dragOver=true"
          @dragleave.prevent="dragOver=false"
          @drop.prevent="onFilesDrop"
        >
          <div
            v-for="(reportTemplate, report_template_index) in store.reportTemplates"
            :key="`file-${report_template_index}`"
          >
            <PreviewTile
              :preview-object="reportTemplate"
              :selectable="true"
              @edit="openReportTemplateModal(reportTemplate)"
              @select="showReportTemplatePreview"
            >
              <template #statusBanner>
                <PreviewTileStatusBanner
                  :report-template="reportTemplate"
                />
              </template>
              <template #previewTileActions>
                <PreviewTileActions
                  :report-template="reportTemplate"
                  :assessment-id="assessmentId"
                  :organisation-id="organisationId"
                  @edit="openReportTemplateModal(reportTemplate)"
                />
              </template>
            </PreviewTile>
          </div>

          <div
            v-if="dragOver"
            class="absolute inset-0 bg-primary-200 opacity-50 rounded pointer-events-none"
          />
        </div>
      </div>
      <div v-else>
        <div
          class="flex mx-auto pt-4 sm:pt-16 pb-4 max-h-[50vh] sm:max-h-[60vh]"
          @dragover.prevent="dragOver=true"
          @dragenter.prevent="dragOver=true"
          @dragleave.prevent="dragOver=false"
          @drop.prevent="onFilesDrop"
        >
          <div class="flex self-stretch grow shrink basis-0 flex-col justify-start items-start">
            <div
              :class="['justify-center items-center flex w-full h-full outline-dashed outline-spacing-4  rounded-xl p-8'
                       , dragOver ? 'outline-4 outline-gray-400':'outline-2 outline-gray-300']"
            >
              <div class="flex flex-col items-center space-y-4 h-full">
                <NoReportTemplates class="my-4" />
                <p class="text-gray-800">
                  {{ $t('components.report_templates.drop_to_upload') }} (DOCX, PPTX)
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <transition
        enter-active-class="transition-transform duration-250 ease-in-out"
        leave-active-class="transition-transform duration-250 ease-in-out"
        enter-from-class="translate-y-12"
        leave-to-class="translate-y-12"
        enter-to-class="translate-y-0"
        leave-from-class="translate-y-0"
      >
        <div
          v-show="dragOver"
          class="bg-primary-300 text-primary-800 p-2 rounded-full absolute bottom-2
           left-1/2 transform -translate-x-1/2  z-10 text-sm"
        >
          <p>{{ $t('components.report_templates.drop_to_upload_with_data_types') }}</p>
        </div>
      </transition>
    </div>
    <ReportTemplateModal
      v-if="showReportTemplateModal"
      :assessment-id="assessmentId"
      :report-template="selectedReportTemplate"
      :template-document="templateFile"
      @close="closeReportTemplateModal"
    />
    <ReportTemplateSelectionModal
      :available-report-templates="availableReportTemplates"
      :assessment-id="assessmentId"
      :open="showReportTemplateSelectionModal"
      @close="closeReportTemplateSelectionModal"
    />
  </template>
  <ReportTemplatePreviewOverlay
    v-if="previewReportTemplate"
    :report-template="previewReportTemplate"
    @close="previewReportTemplateId = null"
  />
  <WebdavSessionUnlockModal />
  <WebdavEditingInfoModal />
</template>

<script>
import NoReportTemplates from '@/components/Templates/ReportTemplates/Graphics/NoReportTemplates.vue';
import PreviewTileActions from '@/components/Templates/ReportTemplates/PreviewTile/PreviewTileActions.vue';
import PreviewTileStatusBanner from '@/components/Templates/ReportTemplates/PreviewTile/PreviewTileStatusBanner.vue';
import ReportTemplateModal from '@/components/Templates/ReportTemplates/ReportTemplateModal.vue';
import ReportTemplatePreviewOverlay from '@/components/Templates/ReportTemplates/ReportTemplatePreviewOverlay/ReportTemplatePreviewOverlay.vue';
import ReportTemplateSelectionModal from '@/components/Templates/ReportTemplates/ReportTemplateSelectionModal.vue';
import UploadButton from '@/components/Templates/ReportTemplates/UploadButton.vue';
import PageHeading from '@/components/generic/PageHeading/PageHeading.vue';
import PreviewTile from '@/components/generic/PreviewTile/PreviewTile.vue';
import { storeToRefs } from 'pinia';
import { posthog } from 'posthog-js';
import { computed, onUnmounted, ref, watch } from 'vue';
import WebdavSessionUnlockModal from '@/components/generic/EditInOffice/WebdavSessionUnlockModal.vue';
import { useReportTemplatesStore } from '../Templates/ReportTemplates/stores/reportTemplatesStore';
import BetaFeatureBadge from '../generic/FeatureBadges/BetaFeatureBadge.vue';
import WebdavEditingInfoModal from '../generic/EditInOffice/WebdavEditingInfoModal.vue';

export default {
  name: 'AssessmentReportTemplates',
  components: {
    PageHeading,
    PreviewTile,
    ReportTemplateModal,
    ReportTemplateSelectionModal,
    UploadButton,
    PreviewTileActions,
    NoReportTemplates,
    PreviewTileStatusBanner,
    ReportTemplatePreviewOverlay,
    BetaFeatureBadge,
    WebdavSessionUnlockModal,
    WebdavEditingInfoModal,
  },
  props: {
    assessmentId: {
      type: [Number, String],
      required: true,
    },
    isActiveAssessment: {
      type: Boolean,
      default: false,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
    reportTemplatesData: {
      type: Object,
      default: null,
    },
    organisationId: {
      type: Number,
      default: null,
    },
    availableReportTemplates: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useReportTemplatesStore();
    store.setReportTemplates(props.reportTemplatesData);
    store.subscribeUpdates({ assessmentId: props.assessmentId });

    onUnmounted(() => {
      store.unsubscribe();
    });

    const fileInputRef = ref(null);
    const selectedReportTemplate = ref(null);
    const templateFile = ref(null);
    const showReportTemplateModal = ref(false);
    const showReportTemplateSelectionModal = ref(false);

    const { replacementReportTemplate } = storeToRefs(store);
    watch(replacementReportTemplate, (newReplacementReportTemplate, _) => {
      selectedReportTemplate.value = newReplacementReportTemplate.reportTemplate;
      templateFile.value = newReplacementReportTemplate.replacementDocument;
      showReportTemplateModal.value = true;
    });

    const acceptedMimeTypes = [
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    ];
    const maxFileSize = 1024 * 1024 * 200; // 200MB
    const selectedTemplateFile = ref(null);

    const onFileUpload = (files, isPending = false) => {
      if (files.length > 0) {
        const [file] = files;
        templateFile.value = isPending
          ? { filename: file.name, content_type: file.type, isPending: true }
          : { filename: file.filename, content_type: file.content_type, signed_id: file.signed_id, isPending: false };
        showReportTemplateModal.value = true;
      }
    };

    const openReportTemplateModal = (template) => {
      selectedReportTemplate.value = template;
      templateFile.value = null;
      showReportTemplateModal.value = true;
    };

    const closeReportTemplateModal = () => {
      selectedReportTemplate.value = null;
      selectedTemplateFile.value = null;
      showReportTemplateModal.value = false;
    };

    const closeReportTemplateSelectionModal = () => {
      showReportTemplateSelectionModal.value = false;
    };

    const openReportTemplateSelectionModal = () => {
      showReportTemplateSelectionModal.value = true;
    };

    const previewReportTemplateId = ref(null);
    const previewReportTemplate = computed(() => {
      if (previewReportTemplateId.value) {
        return store.getReportTemplateById(previewReportTemplateId.value);
      }
      return null;
    });
    const showReportTemplatePreview = (reportTemplate) => {
      previewReportTemplateId.value = reportTemplate.id;
    };

    const dragOver = ref(false);

    const onFilesDrop = async ({ dataTransfer }) => {
      const { files: droppedFiles } = dataTransfer;
      if (droppedFiles.length) {
        const uploadedFiles = await store.uploadSelectedFile(
          [droppedFiles[0]],
          {
            acceptedMimeTypes,
          },
        );
        onFileUpload(uploadedFiles, false);
      }
      dragOver.value = false;
    };

    return {
      store,

      selectedReportTemplate,
      fileInputRef,
      showReportTemplateModal,
      showReportTemplateSelectionModal,

      acceptedMimeTypes,
      maxFileSize,
      selectedTemplateFile,
      templateFile,

      onFileUpload,
      openReportTemplateModal,
      closeReportTemplateModal,
      closeReportTemplateSelectionModal,
      openReportTemplateSelectionModal,

      reportTemplatesFeatureEnabled: (posthog.persistence && posthog.isFeatureEnabled('report-templates'))
      || window.moduleEnabled('reporting'),

      previewReportTemplate,
      previewReportTemplateId,
      showReportTemplatePreview,

      dragOver,
      onFilesDrop,
    };
  },
};
</script>
