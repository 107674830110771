<template>
  <div class="flex flex-col gap-y-6">
    <page-heading>
      {{ $t("defaults.access") }}
      <template #actions>
        <div class="flex items-center space-x-4">
          <search-input />
          <CreateOrganisationMembershipButton
            :available-users="availableUsers"
            :organisation-id="organisationId"
          />
        </div>
      </template>
    </page-heading>
    <BaseTable
      :columns="columns"
      :records="organisationMemberships"
      :pages="pages"
    >
      <template #default="{ record: organisationMembership }">
        <td
          v-dompurify-html:mark="highlightedText(organisationMembership.user.firstName)"
          class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 ph-no-capture"
        />
        <td
          v-dompurify-html:mark="highlightedText(organisationMembership.user.lastName)"
          class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 ph-no-capture"
        />
        <td
          v-dompurify-html:mark="highlightedText(organisationMembership.user.email)"
          class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 ph-no-capture"
        />

        <OrganisationMembershipActions
          :organisation-membership="organisationMembership"
        />
      </template>
    </BaseTable>
  </div>
</template>

<script>
import BaseTable from '@/components/generic/BaseTable/BaseTable.vue';
import { useI18n } from '@/plugins/i18n';
import { useSearchHighlighter } from '@/composables/useSearchHighlighter';
import OrganisationMembershipActions from './OrganisationMembershipActions/OrganisationMembershipActions.vue';
import CreateOrganisationMembershipButton from './CreateOrganisationMembershipButton.vue';

export default {
  components: {
    BaseTable,
    OrganisationMembershipActions,
    CreateOrganisationMembershipButton,
  },
  props: {
    organisationId: {
      type: Number,
      default: null,
    },
    organisationMemberships: {
      type: Array,
      required: true,
    },
    availableUsers: {
      type: Array,
      required: true,
    },
    pages: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  setup() {
    const { t } = useI18n();
    const { highlightedText } = useSearchHighlighter();

    const columns = [
      {
        key: 'first_name',
        name: t('activerecord.attributes.user.first_name'),
        sortable: true,
      },
      {
        key: 'last_name',
        name: t('activerecord.attributes.user.last_name'),
        sortable: true,
      },
      {
        key: 'email',
        name: t('activerecord.attributes.user.email'),
        sortable: true,
      },

      {
        key: 'actions',
        name: t('defaults.manage'),
        hidden: true,
      },
    ];

    return {
      columns,
      highlightedText,
    };
  },
};
</script>
