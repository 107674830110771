<template>
  <RadioList
    v-model="afterLoginView"
    :options="afterLoginViewOptions"
    :name="inputName"
    :show-label="true"
    :label="label"
    :error="error"
  />
</template>

<script>
import { useI18n } from '@/plugins/i18n';
import { ref, defineComponent } from 'vue';

export default defineComponent({
  props: {
    value: {
      type: String,
      required: true,
    },
    inputName: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    error: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const afterLoginView = ref(props.value);
    const { t } = useI18n();

    const afterLoginViewOptions = [
      {
        value: 'admin_view',
        name: t('components.after_login_view.radio_list.admin_view'),
        description: t('components.after_login_view.radio_list.admin_view_description'),
      },
      {
        value: 'observer_view',
        name: t('components.after_login_view.radio_list.observer_view'),
        description: t('components.after_login_view.radio_list.observer_view_description'),
      },
    ];

    return {
      afterLoginView,
      afterLoginViewOptions,
    };
  },
});
</script>
