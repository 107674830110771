<template>
  <chart-card
    :title="$t('components.insights.show.clusters')"
    subtitle="Benchmark"
  >
    <div
      class="relative w-full aspect-square"
    >
      <canvas
        ref="chartElement"
      />
    </div>
  </chart-card>
</template>
<script>
import { ref, onMounted, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import { Chart } from 'chart.js/auto';

import ChartCard from './ChartCard.vue';

export default {
  name: 'CompetencyClustersBenchmarkRadarChart',
  components: {
    ChartCard,
  },
  props: {
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 5,
    },
    competencyClusters: {
      type: Array,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const chartElement = ref(null);
    const { min, max, competencyClusters, data } = toRefs(props);

    onMounted(() => {
      const defaultColors = [
        '#3366CC', '#DC3912', '#FF9900', '#109618', '#990099', '#3B3EAC', '#0099C6',
        '#DD4477', '#66AA00', '#B82E2E', '#316395', '#994499', '#22AA99', '#AAAA11',
        '#6633CC', '#E67300', '#8B0707', '#329262', '#5574A6', '#651067',
      ];

      const labels = [];
      const meanData = [];
      const lowerStdData = [];
      const higherStdData = [];

      competencyClusters.value.forEach((cluster) => {
        labels.push(cluster.name);

        const chartData = data.value.find((row) => row.clusterName === cluster.name);

        meanData.push(chartData?.avg);
        lowerStdData.push(chartData?.avgMinusStddev);
        higherStdData.push(chartData?.avgPlusStddev);
      });

      const chartData = {
        labels,
        datasets: [
          {
            borderColor: defaultColors[0],
            backgroundColor: 'rgba(0, 0, 0, 0)',
            data: meanData,
            label: t('components.insights.show.mean'),
          },
          {
            borderColor: 'rgba(0, 0, 0, 0)',
            pointBackgroundColor: 'rgba(0, 0, 0, 0)',
            fill: false,
            data: lowerStdData,
            label: t('components.insights.show.standard_deviation'),
          },
          {
            borderColor: 'rgba(0, 0, 0, 0)',
            pointBackgroundColor: 'rgba(0, 0, 0, 0)',
            fill: 1,
            data: higherStdData,
          },
        ],
      };

      const config = {
        type: 'radar',
        data: chartData,
        options: {
          locale: 'en',
          maintainAspectRatio: true,
          borderJoinStyle: 'round',
          indexAxis: 'y',
          tension: 0,
          animation: false,
          scales: {
            r: {
              suggestedMin: min.value,
              suggestedMax: max.value,
            },
          },
          plugins: {
            legend: {
              labels: {
                filter(item, _) {
                  return item.text !== undefined;
                },
              },
              position: 'bottom',
            },
            tooltip: {
              displayColors: false,
              callbacks: {
                label: (tooltipItem, _) => {
                  const clusterData = data.value.find((row) => row.clusterName === tooltipItem.label);

                  return [`${t('components.insights.show.mean')}: ${clusterData?.avg} `,
                    `${t('components.insights.show.standard_deviation')}: ${clusterData?.stddev}`];
                },
              },
            },
            title: {
              font: {
                size: 20,
              },
              color: '#333',
            },
          },
          interaction: {},
        },
      };

      // eslint-disable-next-line no-new
      new Chart(
        chartElement.value,
        config,
      );
    });

    return { chartElement };
  },
};
</script>
