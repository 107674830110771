<template>
  <Listbox
    v-model="selectedItem"
    as="div"
  >
    <div class="mt-1 relative">
      <ListboxButton
        class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-6 py-2 text-left
        cursor-default focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
      >
        <span class="block truncate">  <slot
          :option="selectedItem"
        /></span>
        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <SelectorIcon
            class="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </span>
      </ListboxButton>

      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="absolute mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1
          ring-black/5 overflow-auto focus:outline-none sm:text-sm z-10"
        >
          <ListboxOption
            v-for="option in options"
            :key="option"
            v-slot="{ active, selected }"
            as="template"
            :value="option"
          >
            <li
              :class="[active ? `text-white ${customColor.background}` : 'text-gray-900',
                       'cursor-default select-none relative py-2 pl-3 pr-9 focus:outline-none']"
            >
              <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                <slot
                  :option="option"
                />
              </span>

              <span
                v-if="selected"
                :class="[active ? `${customColor.text}` : 'text-primary-500',
                         'absolute inset-y-0 right-0 flex items-center pr-4']"
              >
                <CheckIcon
                  class="h-5 w-5"
                  aria-hidden="true"
                />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script>
import { ref, watchEffect } from 'vue';
import {
  Listbox, ListboxButton, ListboxOption, ListboxOptions,
} from '@headlessui/vue';
import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid';

export default {
  name: 'SelectBox',
  components: {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    SelectorIcon,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: Object,
      required: true,
    },
    customColor: {
      type: Object,
      default: () => ({ background: 'bg-primary-500', text: 'text-white' }),
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const selectedItem = ref(props.modelValue);

    watchEffect(() => {
      emit('update:modelValue', selectedItem);
    });

    return {
      selectedItem,
    };
  },
};
</script>
