<template>
  <div>
    <div
      v-for="(behaviorAnchor, behaviorAnchorIndex) in notDestroyedBehaviorAnchors"
      :key="`behavior-anchor-${behaviorAnchorIndex}`"
      :ref="(el) => el && (behaviorAnchorRefs[behaviorAnchorIndex] = el)"
      class="field"
    >
      <Card>
        <div class="flex flex-row-reverse py-2 mx-4">
          <button
            :disabled="!isEditable"
            @click="behaviorAnchorStore.deleteBehaviorAnchor(behaviorAnchor)"
          >
            <TrashIcon
              class="block h-6 w-6 text-gray-400"
              aria-hidden="true"
            />
          </button>
        </div>
        <div class="py-5 border-t border-b border-gray-200">
          <div class="mx-4">
            <BaseTextarea
              v-model="behaviorAnchor.description"
              :disabled="!isEditable"
              :label="$t('components.rating_anchors_input.description')"
              :error="behaviorAnchorError(behaviorAnchorIndex, 'description')"
              @input="updateDescriptionTextAreaSize"
            />
          </div>
        </div>
        <div class="px-4">
          <div
            v-if="!behaviorAnchor.hasCustomScaleLabels"
            class="flex justify-center py-4 "
          >
            <BaseButton
              icon="PlusIcon"
              :is-disabled="!isEditable"
              :is-primary="false"
              @click="toggleBehaviorAnchorCustomScaleLabels(behaviorAnchor)"
            >
              {{ $t('components.rating_anchors_input.add_custom_scale') }}
            </BaseButton>
          </div>
          <div
            v-else
          >
            <div class="mt-2">
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                {{ $t('components.rating_anchors_input.custom_scale') }}
              </h3>
            </div>

            <div
              class="py-4"
            >
              <div
                v-for="(customScaleItemGroup, index) in behaviorAnchorStore.customScaleLabelGroupsFor(behaviorAnchor)"
                :key="index"
              >
                <div
                  :class="[
                    customScaleItemGroup.items[0]?.exists ?
                      'flex flex-col space-y-4':
                      'flex items-center justify-center py-4 gap-2 flex-wrap'
                  ]"
                >
                  <div
                    v-for="customScaleLabelItem in customScaleItemGroup.items"
                    :key="customScaleLabelItem.scaleValue"
                  >
                    <div
                      v-if="customScaleLabelItem.exists"
                      class="flex"
                    >
                      <BaseTextarea
                        :model-value="customScaleLabelItem.description"
                        :disabled="!isEditable"
                        class="w-full"
                        @update:model-value="behaviorAnchorStore
                          .updateCustomScaleLabel(behaviorAnchor, customScaleLabelItem.scaleValue, $event)"
                        @input="updateDescriptionTextAreaSize"
                      >
                        <template #leading-addon>
                          {{ customScaleLabelItem.scaleValue }}
                        </template>
                      </BaseTextarea>
                      <button
                        class="pl-4"
                        :disabled="!isEditable"
                        @click="behaviorAnchorStore
                          .deleteCustomScaleLabel(behaviorAnchor, customScaleLabelItem.scaleValue)"
                        @blur="update"
                      >
                        <XIcon
                          class="block h-6 w-6 text-gray-400"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                    <div
                      v-else
                      class="flex items-center justify-center gap-2 flex-wrap"
                    >
                      <BaseButton
                        :key="customScaleLabelItem.scaleValue"
                        icon="PlusIcon"
                        :is-primary="false"
                        :is-disabled="!isEditable"
                        :class="{
                          'h-8': !customScaleLabelItem.large
                        }"
                        @click="behaviorAnchorStore
                          .addCustomScaleLabel(behaviorAnchor, customScaleLabelItem.scaleValue)"
                      >
                        {{ customScaleLabelItem.scaleValue }}
                      </BaseButton>
                    </div>
                  </div>
                  <BaseButton
                    v-if="showAddAllCustomScaleLabelsButton && customScaleItemGroup.isLastNotExisting"
                    icon="PlusIcon"
                    :is-primary="false"
                    :is-disabled="!isEditable"
                    @click="behaviorAnchorStore.addAllMissingCustomScaleLabels(behaviorAnchor)"
                  >
                    {{ $t('components.rating_anchors_input.all') }}
                  </BaseButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
      <hr>
    </div>
    <div
      v-if="isEditable"
      class="flex justify-center my-2"
    >
      <div
        class="flex justify-center py-4 "
      >
        <BaseButton
          icon="PlusIcon"
          :is-primary="false"
          @click="addBehaviorAnchorEdit"
        >
          {{ $t('components.rating_anchors_input.add_behavior_anchor') }}
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ref, onBeforeUpdate, onMounted, nextTick,
} from 'vue';
import { storeToRefs } from 'pinia';
import { TrashIcon, XIcon } from '@heroicons/vue/outline';
import BaseTextarea from '../generic/BaseTextarea/BaseTextarea.vue';
import { useRatingAnchorStore } from './ratingAnchorStore';
import { useBehaviorAnchorsStore } from './behaviorAnchorsStore';

export default {
  name: 'BehaviorAnchorsFormFields',
  components: {
    TrashIcon,
    XIcon,
    BaseTextarea,
  },
  props: {
    isEditable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const ratingAnchorStore = useRatingAnchorStore();
    const { errors } = storeToRefs(ratingAnchorStore);
    const behaviorAnchorStore = useBehaviorAnchorsStore();
    const { notDestroyedBehaviorAnchors, showAddAllCustomScaleLabelsButton } = storeToRefs(behaviorAnchorStore);

    const behaviorAnchorRefs = ref([]);
    const scaleLabelWrapperRefs = ref([]);

    onBeforeUpdate(() => {
      behaviorAnchorRefs.value = [];
      scaleLabelWrapperRefs.value = [];
    });

    const autoResize = (target) => {
      /* eslint-disable no-param-reassign */
      target.style.height = 'auto';
      target.style.height = `${target.scrollHeight}px`;
    };

    onMounted(async () => {
      await nextTick();
      behaviorAnchorRefs.value.forEach((textarea) => {
        textarea.getElementsByTagName('textarea').forEach((el) => {
          autoResize(el);
        });
      });
    });

    const updateDescriptionTextAreaSize = (event) => {
      autoResize(event.target);
    };

    const behaviorAnchorError = (index, attribute) => errors.value[`behavior_anchors[${index}].${attribute}`]?.[0];

    const toggleBehaviorAnchorCustomScaleLabels = (behaviorAnchor) => {
      behaviorAnchor.hasCustomScaleLabels = !behaviorAnchor.hasCustomScaleLabels;
    };

    const addBehaviorAnchorEdit = async () => {
      behaviorAnchorStore.addBehaviorAnchor();

      await nextTick();
      behaviorAnchorRefs.value
        .slice(-1)[0]
        .getElementsByTagName('textarea')[0]
        .focus();
    };

    const scaleLabelTextAreasForBehaviorAnchor = (behaviorAnchorIndex) => {
      const anchorTextArea = scaleLabelWrapperRefs.value[behaviorAnchorIndex]?.querySelectorAll('textarea');
      return anchorTextArea || [];
    };

    return {
      behaviorAnchorStore,
      notDestroyedBehaviorAnchors,
      behaviorAnchorRefs,
      scaleLabelWrapperRefs,
      showAddAllCustomScaleLabelsButton,
      behaviorAnchorError,
      addBehaviorAnchorEdit,
      updateDescriptionTextAreaSize,
      toggleBehaviorAnchorCustomScaleLabels,
      scaleLabelTextAreasForBehaviorAnchor,
    };
  },
};
</script>
./behaviorAnchorsStore
