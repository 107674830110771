<template>
  <BaseTableActions>
    <template #menu-items>
      <ActionDropdownItem
        class="flex flex-row"
        @click="$emit('show-report-template-modal', reportTemplate)"
      >
        <div class="mr-4">
          <NewspaperIcon
            class="h-5 w-5 text-gray-500"
            aria-hidden="true"
          />
        </div>
        <span class="text-gray-700">{{ $t('defaults.view') }}</span>
      </ActionDropdownItem>
      <EditInOfficeActionDropdownItem
        :file="reportTemplate"
        file-type="ReportTemplate"
      />
      <ActionDropdownItem
        class="flex flex-row cursor-pointer"
        @click="$emit('edit-report-template-modal', reportTemplate)"
      >
        <div class="mr-4">
          <PencilIcon
            class="h-5 w-5 text-gray-500"
            aria-hidden="true"
          />
        </div>
        <span class="text-gray-700">{{ $t('defaults.edit') }}</span>
      </ActionDropdownItem>
      <ReplaceReportTemplateDocumentDropdownItem
        :report-template="reportTemplate"
        :is-disabled="false"
      />
      <DownloadReportDocumentDropdownItem
        :document="reportTemplate.templateDocument"
      >
        {{ $t('defaults.download') }}
      </DownloadReportDocumentDropdownItem>
      <LinkDropdownItem
        :href="deletePath"
        method="delete"
        :remote="true"
        :confirm="confirmDeletionMessage"
        :challenge="reportTemplate.name"
        class="flex flex-row border-t border-gray-100"
      >
        <div class="mr-4">
          <TrashIcon
            class="h-5 w-5 text-red-500"
            aria-hidden="true"
          />
        </div>
        <span class="text-red-700">{{ $t('defaults.delete') }}</span>
      </LinkDropdownItem>
    </template>
  </BaseTableActions>
</template>

<script>
import DownloadReportDocumentDropdownItem from '@/components/Templates/ReportTemplates/DownloadReportDocumentDropdownItem.vue';
import BaseTableActions from '@/components/generic/BaseTable/BaseTableActions/BaseTableActions.vue';
import ActionDropdownItem from '@/components/generic/Dropdown/ActionDropdownItem/ActionDropdownItem.vue';
import LinkDropdownItem from '@/components/generic/Dropdown/LinkDropdownItem/LinkDropdownItem.vue';
import { useI18n } from '@/plugins/i18n';
import { reportTemplatePath, organisationReportTemplatePath } from '@/util/url-helpers';
import { NewspaperIcon } from '@heroicons/vue/outline';
import { PencilIcon, TrashIcon } from '@heroicons/vue/solid';
import { computed, defineComponent, ref, toRefs } from 'vue';
import EditInOfficeActionDropdownItem from '@/components/generic/EditInOffice/EditInOfficeActionDropdownItem.vue';
import ReplaceReportTemplateDocumentDropdownItem from '../../ReplaceReportTemplateDocumentDropdownItem.vue';

export default defineComponent({
  components: {
    NewspaperIcon,
    PencilIcon,
    TrashIcon,
    LinkDropdownItem,
    BaseTableActions,
    ActionDropdownItem,
    DownloadReportDocumentDropdownItem,
    ReplaceReportTemplateDocumentDropdownItem,
    EditInOfficeActionDropdownItem,
  },
  props: {
    reportTemplate: {
      type: Object,
      required: true,
    },
    organisationId: {
      type: Number,
      default: null,
    },
  },
  emits: ['edit-report-template-modal', 'show-report-template-modal', 'start-uploading-files', 'show-unlock-modal'],
  setup(props) {
    const { reportTemplate } = toRefs(props);
    const { t } = useI18n();

    const showReportTemplateModal = ref(false);

    const deletePath = computed(() => (props.organisationId
      ? organisationReportTemplatePath(props.organisationId, props.reportTemplate)
      : reportTemplatePath(props.reportTemplate)));

    const confirmDeletionMessage = computed(() => {
      const messages = [t('components.report_templates.report_template_actions.do_you_want_to_delete', {
        name: reportTemplate.value.name,
      })];

      if (props.reportTemplate.hasCompetenceModelAssignments) {
        messages.push(t('components.report_templates.report_template_actions.delete_competence_model_assignments'));
      }

      return messages.join(' ');
    });

    return {
      confirmDeletionMessage,
      deletePath,
      showReportTemplateModal,
    };
  },
});
</script>
