<template>
  <div class="flex flex-col gap-y-6">
    <page-heading>
      {{ $t('activerecord.models.organisation.other') }}
      <template #actions>
        <div class="flex items-center space-x-4">
          <search-input />
          <BaseButton
            v-if="userCanManageOrganisations"
            as="a"
            :href="newOrganisationPath"
            qa-class="organisations-new-button"
            icon="PlusIcon"
          >
            {{ $t('components.organisations.new_organisation') }}
          </BaseButton>
        </div>
      </template>
    </page-heading>

    <div v-if="organisationsPresent">
      <BaseTable
        :columns="columns"
        :records="organisations"
        :pages="pages"
        :emit-row-click="true"
        @on-row-click="visitRecord($event)"
      >
        <template #default="{ record: organisation }">
          <td
            v-dompurify-html:mark="highlightedText(organisation.name)"
            class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 ph-no-capture"
          />
          <td
            v-dompurify-html:mark="highlightedText(organisation.unit)"
            class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 ph-no-capture"
          />

          <OrganisationActions
            v-if="userCanManageOrganisations"
            v-model="active"
            :organisation="organisation"
            @merge-organisation="showMergeOrganisationModal"
          />
        </template>
      </BaseTable>
    </div>
    <div
      v-else
      class="mt-32"
    >
      <div
        class="text-center object-center content-center justify-center space-y-6"
      >
        <div>
          <svg
            class="mx-auto h-12 w-12 text-gray-400"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 24 24"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1"
              d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            />
          </svg>
          <p
            class="text-gray-900 text-sm leading-5 font-medium"
          >
            {{ $t('components.organisations.organisations_table.organisations_empty_state.header') }}
          </p>
          <p
            class="text-gray-500 text-sm leading-5 font-normal"
          >
            {{ $t('components.organisations.organisations_table.organisations_empty_state.header_helptext') }}
          </p>
        </div>
        <div v-if="userCanManageOrganisations">
          <base-button
            as="a"
            :href="newOrganisationPath"
            icon="PlusIcon"
          >
            {{ $t('components.organisations.organisations_table.organisations_empty_state.create_button') }}
          </base-button>
        </div>
      </div>
    </div>
  </div>
  <MergeOrganisationsModal
    :open="mergeOrganisationModalOpen"
    :organisations="organisations"
    :organisation="organisationToMerge"
    @close="mergeOrganisationModalOpen = false"
  />
</template>

<script>
import BaseTable from '@/components/generic/BaseTable/BaseTable.vue';
import { useI18n } from '@/plugins/i18n';
import { createOrganisationPath, editOrganisationPath } from '@/util/url-helpers';
import { ref } from 'vue';
import { useSearchHighlighter } from '@/composables/useSearchHighlighter';
import OrganisationActions from './OrganisationActions/OrganisationActions.vue';
import MergeOrganisationsModal from './MergeOrganisationsModal.vue';

export default {
  components: {
    BaseTable,
    OrganisationActions,
    MergeOrganisationsModal,
  },
  props: {
    organisations: {
      type: Array,
      required: true,
    },
    pages: {
      type: Array,
      required: true,
    },
    organisationsPresent: {
      type: Boolean,
      required: true,
    },
    organisationMembershipOrganisationIds: {
      type: Array,
      required: true,
    },
    userCanManageOrganisations: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();

    const active = ref(false);
    const openFilterModal = ref(false);
    const { highlightedText } = useSearchHighlighter();

    const columns = [
      {
        key: 'name',
        name: t('activerecord.attributes.organisation.name'),
        sortable: true,
      },
      {
        key: 'unit',
        name: t('activerecord.attributes.organisation.unit'),
        sortable: true,
      },
    ];

    if (props.userCanManageOrganisations) {
      columns.push({
        key: 'actions',
        name: t('defaults.manage'),
        hidden: true,
      });
    }

    const newOrganisationPath = createOrganisationPath();

    const mergeOrganisationModalOpen = ref(false);
    const organisationToMerge = ref(null);

    const showMergeOrganisationModal = (organisation) => {
      mergeOrganisationModalOpen.value = true;
      organisationToMerge.value = organisation;
    };

    const visitRecord = (record) => {
      const path = record.access === 'public_access' || props.organisationMembershipOrganisationIds.includes(record.id)
        ? record.path
        : editOrganisationPath(record);

      Turbo.visit(path);
    };

    return {
      columns,
      newOrganisationPath,
      active,
      openFilterModal,
      highlightedText,

      mergeOrganisationModalOpen,
      organisationToMerge,
      showMergeOrganisationModal,
      visitRecord,
    };
  },
};
</script>
