<template>
  <div class="mb-6">
    <PageHeading>
      <div class="ph-no-capture">
        {{ observer.user.name }}
      </div>
      <template #actions>
        <div class="flex space-x-5">
          <BaseButton
            v-if="assessmentEditable"
            :is-primary="false"
            :is-disabled="!assessmentEditable || submitting"
            icon="TrashIcon"
            @click="showDeleteModal = true"
          >
            {{ $t('defaults.delete') }}
          </BaseButton>
          <SaveButton
            v-if="assessmentEditable"
            :is-loading="submitting"
            form="edit-observer-form"
          >
            {{ $t('defaults.save') }}
          </SaveButton>
        </div>
      </template>
    </PageHeading>
  </div>
  <form
    id="edit-observer-form"
    class="mb-4"
    :action="actionUrl"
    accept-charset="UTF-8"

    method="post"
    enctype="multipart/form-data"
    @submit="submitting = true"
  >
    <input
      type="hidden"
      name="_method"
      value="patch"
    >
    <input
      type="hidden"
      name="authenticity_token"
      :value="$csrfToken"
    >
    <div class="space-y-5">
      <TwoColumnCard
        :header="$t('components.new_observer_form.role')"
        :helptext="$t('components.new_observer_form.categories.observer.helptext')"
      >
        <div class="space-y-6">
          <RadioList
            v-model="observer.role"
            :options="observerRoleOptions"
            name="observer_assignment[role]"
            :disabled="!assessmentEditable"
            :label="$t('components.new_observer_form.role')"
            :error="getError('role')"
          />
          <div
            class="flex justify-between items-center px-2"
          >
            <div>
              <h3 class="text-gray-900 block text-sm font-medium">
                {{ $t('components.new_observer_form.allow_export.header') }}
              </h3>
              <p class="text-gray-500 block text-sm">
                {{ $t('components.new_observer_form.allow_export.text') }}
              </p>
            </div>
            <Toggle
              :model-value="observer.allow_export"
              :disabled="!assessmentEditable"
              name="observer_assignment[allow_export]"
              @update:model-value="observer.allow_export = $event"
            />
          </div>
        </div>
      </TwoColumnCard>

      <Card>
        <MultiFileUpload
          accepted-mime-types="image/jpeg, image/png, image/gif, application/pdf, video/quicktime, video/mp4"
          accepted-file-types="PDF, PNG, JPEG, MOV, MP4"
          :collection-path="collectionPath"
          :has-shareable-files="false"
          :disabled="!assessmentEditable"
          :use-primary-button="false"
        />
      </Card>
    </div>
  </form>
  <DeleteModal
    v-if="assessmentEditable"
    :title="$t('components.observers.delete_observer')"
    :challenge="observer.user.name"
    :open="showDeleteModal"
    :message="deleteMessage"
    @close="showDeleteModal = false"
    @delete="deleteObserver"
  />
</template>

<script>
import { assessmentObserverAssignmentPath, organisationAssessmentObserverAssignmentPath } from '@/util/url-helpers';
import { useI18n } from 'vue-i18n';
import SaveButton from '@/components/generic/SaveButton/SaveButton.vue';
import RadioList from '@/components/generic/RadioList/RadioList.vue';
import TwoColumnCard from '@/components/generic/TwoColumnCard/TwoColumnCard.vue';
import PageHeading from '@/components/generic/PageHeading/PageHeading.vue';
import BaseButton from '@/components/generic/BaseButton/BaseButton.vue';
import DeleteModal from '@/components/generic/DeleteModal/DeleteModal.vue';
import MultiFileUpload from '@/components/generic/MultiFileUpload/MultiFileUpload.vue';
import useObserverRoleOptions from '@/composables/observerRoleOptions';
import { computed, ref } from 'vue';
import Axios from 'axios';

export default {
  name: 'EditObserverForm',
  components: {
    PageHeading,
    BaseButton,
    DeleteModal,
    TwoColumnCard,
    MultiFileUpload,
    RadioList,
    SaveButton,
  },
  props: {
    assessmentId: {
      type: String,
      required: true,
    },
    organisationId: {
      type: Number,
      required: false,
      default: null,
    },
    observerData: {
      type: Object,
      required: true,
    },
    assessmentEditable: {
      type: Boolean,
      required: true,
    },
    assessmentStatus: {
      type: String,
      required: true,
    },
    error: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    collectionPath: {
      type: String,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { t } = useI18n();

    const observer = ref({ ...props.observerData });

    const showDeleteModal = ref(false);
    const submitting = ref(false);

    const selectedRole = ref(props.roleOptions[1]);

    const { observerRoleOptions } = useObserverRoleOptions(props.roleOptions);

    const actionUrl = props.organisationId
      ? organisationAssessmentObserverAssignmentPath(props.organisationId, props.assessmentId, observer.value.id)
      : assessmentObserverAssignmentPath(props.assessmentId, observer.value.id);

    const deleteObserver = () => {
      Axios.delete(actionUrl).then((response) => {
        Turbo.visit(response.request.responseURL, { action: 'replace' });
      });
    };

    const getError = (...keys) => props.errors[keys.join('.')]?.[0];

    const deleteMessage = computed(() => (props.assessmentStatus === 'active'
      ? t('components.observers.deleted_warning_text')
      : t('components.observers.do_you_want_to_delete', { name: observer.value.user.name })));

    return {
      observerRoleOptions,
      selectedRole,
      actionUrl,
      submitting,
      deleteMessage,
      observer,
      showDeleteModal,

      getError,
      deleteObserver,
    };
  },
};
</script>
