<template>
  <div
    class="border rounded-lg m-2 p-2 h-12 flex justify-center items-center
      text-center hover:bg-gray-50 text-primary-500"
    :class="{
      'cursor-pointer': isInteractive,
      'pointer-events-none': !isInteractive ,
      'border-dashed border-black': draggedOn,
    }"
    @click="click"
    @dragenter="dragEnter"
    @dragleave="dragLeave"
    @dragover.prevent
    @drop.prevent="drop"
  >
    <!-- eslint-disable-next-line max-len -->
    <Heroicon
      icon="PlusIcon"
      class="h-4 w-4 pointer-events-none text-black"
    />
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import Heroicon from '@/components/Heroicon.vue';

export default defineComponent({
  name: 'NewAnchorItem',
  components: {
    Heroicon,
  },
  props: {
    path: {
      type: String,
      required: true,
    },
    isInteractive: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['anchor-dropped'],
  setup(props, { emit }) {
    const draggedOn = ref(false);

    const dragEnter = () => {
      draggedOn.value = true;
    };

    const dragLeave = () => {
      draggedOn.value = false;
    };

    const drop = (event) => {
      draggedOn.value = false;
      emit('anchor-dropped', event.dataTransfer.getData('text/plain'));
    };

    return {
      dragEnter,
      dragLeave,
      draggedOn,
      drop,
      click() { Turbo.visit(props.path); },
    };
  },
});
</script>
