<template>
  <div class="h-14 bg-black w-full flex items-center text-white relative">
    <div class="absolute left-0 h-full items-center text-white">
      <div class="flex items-center h-full">
        <button
          @click="$emit('close')"
        >
          <Heroicon
            class="h-6 w-14"
            icon="ArrowSmLeftIcon"
          />
        </button>
        <p class="xl:flex hidden space-x-1">
          <heroicon
            class="h-6 w-6"
            icon="DocumentTextIcon"
          />
          <span class="ph-no-capture">{{ `${reportTemplate?.name}${reportTemplate?.fileExtension}` }}</span>
        </p>
      </div>
    </div>

    <div class="absolute left-1/2 transform -translate-x-1/2 space-x-8">
      <PreviewOverlayHeaderTabs
        v-model="internalSelectedTab"
        :tabs="tabs"
      />
    </div>

    <div
      class="h-full absolute right-0 flex items-center"
      :class="[documentExists ? 'text-white cursor-pointer' : 'text-gray-400']"
    >
      <EditInOfficePreviewOverlayButton
        v-if="reportTemplate.templateDocument"
        class="mr-2"
        :file="reportTemplate"
        file-type="ReportTemplate"
      />
      <button
        class="h-full"
        :disabled="!documentExists"
        @click.prevent="$emit('download')"
      >
        <heroicon
          class="h-6 w-14"
          icon="DownloadIcon"
        />
      </button>
    </div>
  </div>
  <div
    v-if="reportTemplate.error"
    class="h-12 w-full bg-red-100 text-red-800 flex justify-center items-center"
  >
    <div class="flex items-center">
      <ExclamationIcon
        class="w-6 h-6 text-red-800 mr-4 font-medium"
      />
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import Heroicon from '@/components/Heroicon.vue';
import { ExclamationIcon } from '@heroicons/vue/outline';
import {
  defineComponent, toRefs, watch, computed, ref, reactive,
} from 'vue';
import { useI18n } from 'vue-i18n';
import EditInOfficePreviewOverlayButton from '@/components/generic/EditInOffice/EditInOfficePreviewOverlayButton.vue';
import PreviewOverlayHeaderTabs from './PreviewOverlayHeaderTabs.vue';

export default defineComponent({
  components: {
    Heroicon,
    PreviewOverlayHeaderTabs,
    ExclamationIcon,
    EditInOfficePreviewOverlayButton,
  },
  props: {
    reportTemplate: {
      type: Object,
      required: true,
    },
    selectedTab: {
      type: [Object, null],
      required: true,
    },
  },
  emits: ['close', 'download', 'print', 'update:selectedTab'],
  setup(props, { emit }) {
    const { reportTemplate } = toRefs(props);

    const { t } = useI18n();

    const tabs = computed(() => (reactive([
      {
        name: t('components.report_templates.report_template_preview_overlay.template_tab'),
        file: computed(() => reportTemplate.value.templateDocument),
        filePdf: computed(() => reportTemplate.value.templateDocumentPdf),
      },
      {
        name: t('components.report_templates.report_template_preview_overlay.preview_tab'),
        file: computed(() => reportTemplate.value.exampleDocument),
        filePdf: computed(() => reportTemplate.value.exampleDocumentPdf),
        error: computed(() => reportTemplate.value.error),
      },
      ...(reportTemplate.value.exampleDocumentPdf && !reportTemplate.value.error ? [
        {
          name: t('components.report_templates.report_template_preview_overlay.show_both'),
          files: computed(() => [reportTemplate.value.templateDocument, reportTemplate.value.exampleDocument]),
          filePdfs: computed(() => [reportTemplate.value.templateDocumentPdf, reportTemplate.value.exampleDocumentPdf]),
          hideOnMobile: true,
        },
      ] : []),
    ].filter((item) => item))));

    const internalSelectedTab = ref(tabs.value[0]);

    watch(internalSelectedTab, (newVal) => {
      emit('update:selectedTab', newVal);
    }, { immediate: true });

    const documentExists = computed(() => !!internalSelectedTab.value?.file?.url
    || !!internalSelectedTab.value?.files?.[0]?.url);
    const errorMessage = computed(() => {
      if (reportTemplate.value?.contentType?.includes('presentation')) {
        return t('components.report_templates.report_template_preview_overlay.error_message_pptx');
      }
      return t('components.report_templates.report_template_preview_overlay.error_message');
    });

    return {
      tabs,
      internalSelectedTab,
      documentExists,
      errorMessage,
    };
  },
});
</script>
