<template>
  <Teleport to="body">
    <img
      class="mx-auto h-20 w-auto bottom-4 right-4 z-50 cursor-pointer none sm:absolute"
      :src="image"
      alt="Bernd die Beobachtungsmappe"
      @click="toggleModal"
    >
  </Teleport>
  <Modal
    :open="modalOpen"
    inner-modal-classes="h-[75vh] w-full flex flex-col"
  >
    <div class="w-full flex justify-between p-2 bg-gray-100">
      <h1 class="text-gray-600 font-bold">
        {{ $t('components.chatbot.title') }}
      </h1>
      <button
        type="button"
        class="text-gray-400 hover:text-primary-500 focus:ring-2
                             focus:ring-primary-500 focus:outline-none"
        @click="toggleModal"
      >
        <span class="sr-only">Close panel</span>
        <Heroicon
          class="h-6 w-6"
          icon="XIcon"
          aria-hidden="true"
        />
      </button>
    </div>
    <div class="h-full flex justify-center">
      <iframe
        class="flex-1 px-2"
        src="https://support-bot-ui.vercel.app/"
      />
    </div>
  </Modal>
</template>
<script setup>
import { ref } from 'vue';
import Heroicon from '@/components/Heroicon.vue';
import Modal from '../generic/Modal/Modal.vue';

const image = '/bernd.png';

const modalOpen = ref(false);

const toggleModal = () => {
  modalOpen.value = !modalOpen.value;
};

</script>
