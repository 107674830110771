<template>
  <Card>
    <div class="flex flex-col p-6">
      <div class="flex w-full space-x-4 justify-between">
        <div>
          <span class="text-gray-900 text-lg leading-6 font-medium block">
            {{ $t('components.assessment_workflow_setting.header') }}
          </span>
          <span class="text-gray-500 text-sm leading-5 block mt-1">
            {{ $t('components.assessment_workflow_setting.help_text') }}
            <a
              :href="$t('components.assessment_workflow_setting.support_link')"
              class="text-primary-500 text-sm leading-5 inline-block"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ $t('defaults.more_information') }}
            </a>
          </span>
        </div>
        <div>
          <BaseButton
            :as="buttonType"
            :href="linkToAssessmentForm"
            :is-disabled="isDisabled"
            :light-colors="true"
            icon="PencilIcon"
            @click="showAssessmentWorkflowModal = true"
          >
            {{ $t('components.assessment_workflow_setting.edit_button') }}
          </BaseButton>
        </div>
      </div>
      <div class="grow min-w-0 mt-4 md:mt-0 overflow-x-auto">
        <div class="w-full">
          <div class="flex">
            <div class="m-0 flex w-full min-w-[985px] items-center py-8">
              <StateTransition
                :state-name="$t('activerecord.enums.assessment.status.draft')"
                color="gray"
                transition-name="activation_transition"
              />
              <StateTransition
                :state-name="$t('activerecord.enums.assessment.status.active')"
                color="green"
                transition-name="completion_transition"
              />
              <StateTransition
                :state-name="$t('activerecord.enums.assessment.status.completed')"
                color="blue"
                transition-name="finalization_transition"
              />
              <Badge
                :color="store.getFinalStatusColor(store.finalStatus)"
              >
                {{ $t(`components.assessment_workflow_setting.final_status.${store.finalStatus}`) }}
              </Badge>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Card>

  <input
    type="hidden"
    :name="`${associationField}[auto_activation]`"
    :value="store.activationTransition.automatic"
  >
  <input
    type="hidden"
    :name="`${associationField}[auto_completion]`"
    :value="store.completionTransition.automatic"
  >
  <input
    type="hidden"
    :name="`${associationField}[auto_finalization]`"
    :value="store.finalizationTransition.automatic"
  >
  <input
    type="hidden"
    :name="`${associationField}[interval_to_active]`"
    :value="store.durationInHours(store.activationTransition.interval)"
  >
  <input
    type="hidden"
    :name="`${associationField}[interval_to_completed]`"
    :value="store.durationInDays(store.completionTransition.interval)"
  >
  <input
    type="hidden"
    :name="`${associationField}[interval_to_final_status]`"
    :value="store.durationInWeeks(store.finalizationTransition.interval)"
  >
  <input
    type="hidden"
    :name="`${associationField}[final_status]`"
    :value="store.finalStatus"
  >

  <AssessmentWorkflowSettingsModal
    :open="showAssessmentWorkflowModal"
    @close="showAssessmentWorkflowModal = false"
  />
</template>

<script>
import Card from '@/components/generic/Card/Card.vue';
import { ref } from 'vue';
import { useAssessmentWorkflowSettingsStore } from '@/components/AssessmentWorkflowSettingsCard/stores/assessmentWorkflowSettingsStore';
import AssessmentWorkflowSettingsModal from '@/components/AssessmentWorkflowSettingsCard/AssessmentWorkflowSettingsModal.vue';
import StateTransition from './StateTransition.vue';

export default {
  name: 'AssessmentWorkflowSettingsCard',
  components: {
    Card,
    StateTransition,
    AssessmentWorkflowSettingsModal,
  },
  props: {
    assessmentWorkflowSettings: {
      type: Object,
      required: true,
    },
    assessmentId: {
      type: Number,
      default: null,
      required: false,
    },
    organisationId: {
      type: Number,
      default: null,
      required: false,
    },
    assessmentCompletedAt: {
      type: String,
      default: null,
      required: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isStatusPage: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const workflowSettings = ref(props.assessmentWorkflowSettings);
    let associationField;
    switch (workflowSettings.value.associatedResource) {
      case 'assessment':
        associationField = 'assessment[assessment_workflow_setting_attributes]';
        break;
      case 'organisation':
        associationField = 'organisation[organisation_workflow_setting_attributes]';
        break;
      default:
        associationField = 'tenant[default_assessment_workflow_attributes]';
        break;
    }

    const store = useAssessmentWorkflowSettingsStore();
    store.initAssessmentWorkflowSettings({ ...workflowSettings.value }, props.assessmentCompletedAt);

    const showAssessmentWorkflowModal = ref(false);

    let linkToAssessmentForm = null;
    if (props.isStatusPage) {
      linkToAssessmentForm = props.organisationId
        ? `/organisations/${props.organisationId}/assessments/${props.assessmentId}/edit/`
        : `/assessments/${props.assessmentId}/edit/`;
    }

    return {
      store,
      showAssessmentWorkflowModal,
      associationField,
      linkToAssessmentForm,
      buttonType: props.isStatusPage ? 'a' : 'button',
    };
  },
};
</script>
