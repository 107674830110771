<template>
  <BaseTable
    :columns="columns"
    :records="candidatesArray"
    :pages="pages"
    :standalone="standalone"
    :preview="preview"
    :view-all-link="viewAllLink"
    :emit-row-click="true"
    @on-row-click="visitCandidatePath"
  >
    <template #default="{ record: candidate }">
      <td class="pl-6 py-4 text-center">
        <Badge
          v-if="candidate.demo"
          :custom-color="candidate.color"
        >
          Demo
        </Badge>
        <div
          v-else
          class="rounded-full h-6 w-6 inline-block align-middle"
          :style="{ 'background-color': candidate.color }"
        />
      </td>
      <td class="ph-no-capture px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        {{ candidate.firstName }}
      </td>
      <td class="ph-no-capture px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        {{ candidate.lastName }}
      </td>
      <td class="ph-no-capture px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-700">
        {{ candidate.email }}
      </td>
      <td
        v-if="testsIsEnabled"
        class="px-6 py-4 whitespace-nowrap"
      >
        <div v-if="candidate.tests.length">
          <TestStatus
            :test-status="candidate.testsStatus"
            :test-progress="candidate.testsProgress"
          />
        </div>
      </td>
      <td class="ph-no-capture px-6 py-4 whitespace-nowrap">
        <FileTiles :files="candidate.shareableFiles" />
      </td>

      <CandidateActions
        v-if="standalone"
        :candidate="candidate"
        :organisation-id="organisationId"
        :assessment-editable="assessmentEditable"
        :assessment-active="assessmentActive"
      />
    </template>
  </BaseTable>
</template>

<script>
import BaseTable from '@/components/generic/BaseTable/BaseTable.vue';
import { useI18n } from '@/plugins/i18n';
import { editOrganisationAssessmentCandidatesPath } from '@/util/url-helpers';
import FileTiles from '@/components/FileTiles/FileTiles.vue';
import TestStatus from '@/components/Candidates/EditCandidate/TestStatus.vue';
import CandidateActions from './CandidateActions/CandidateActions.vue';

export default {
  components: {
    BaseTable,
    FileTiles,
    CandidateActions,
    TestStatus,
  },
  props: {
    organisationId: {
      type: Number,
      default: null,
    },
    candidates: {
      type: Array,
      required: true,
    },
    pages: {
      type: Array,
      default: () => [],
      required: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    standalone: {
      type: Boolean,
      default: true,
    },
    viewAllLink: {
      type: String,
      default: '/',
    },
    assessmentEditable: {
      type: Boolean,
      default: false,
      required: false,
    },
    assessmentActive: {
      type: Boolean,
      default: false,
      required: false,
    },
    testsIsEnabled: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();

    const columns = [
      {
        key: 'color',
        name: '',
        sortable: false,
      },
      {
        key: 'first_name',
        name: t('activerecord.attributes.candidate.first_name'),
        sortable: true,
      },
      {
        key: 'last_name',
        name: t('activerecord.attributes.candidate.last_name'),
        sortable: true,
      },
      {
        key: 'email',
        name: t('activerecord.attributes.candidate.email'),
        sortable: true,
      },
      ...(props.testsIsEnabled
        ? [{
          key: 'tests',
          name: t('activerecord.attributes.candidate.tests'),
        }]
        : []),
      {
        key: 'files',
        name: t('activerecord.attributes.candidate.files'),
        sortable: false,
      },
      {
        key: 'actions',
        name: t('defaults.manage'),
        hidden: true,
      },
    ];

    const candidatesArray = props.candidates.map((c) => {
      const { tests } = c;
      const testCount = tests.length;

      const completedTestsCount = tests.filter((test) => test.status === 'completed').length;
      c.testsProgress = `(${completedTestsCount}/${testCount})`;

      const hasTestWithError = tests.some((test) => test.status === 'error');
      if (hasTestWithError) {
        c.testsStatus = 'error';
        return c;
      }

      const allTestsAreCompleted = completedTestsCount === testCount;
      if (allTestsAreCompleted) {
        c.testsStatus = 'completed';
        return c;
      }

      const noTestWasStarted = tests.every((test) => test.status === 'waiting');
      if (noTestWasStarted) {
        c.testsStatus = 'waiting';
        return c;
      }

      c.testsStatus = 'started';
      return c;
    });

    const visitCandidatePath = (candidate) => {
      const editPath = props.organisationId
        ? editOrganisationAssessmentCandidatesPath(props.organisationId, candidate.assessmentId, candidate)
        : candidate.path;
      Turbo.visit(editPath);
    };

    return {
      columns,
      candidatesArray,
      visitCandidatePath,
    };
  },
};
</script>
