<template>
  <BaseTableActions>
    <template #menu-items>
      <LinkDropdownItem
        :href="editPath"
        class="flex flex-row"
      >
        <div class="mr-4">
          <PencilIcon
            class="h-5 w-5 text-gray-500"
            aria-hidden="true"
          />
        </div>
        <span class="text-gray-700">{{ $t('defaults.edit') }}</span>
      </LinkDropdownItem>

      <ActionDropdownItem
        class="flex flex-row"
        @click="openReplaceDialog"
      >
        <div class="mr-4">
          <SwitchHorizontalIcon
            class="h-5 w-5 text-gray-500"
            aria-hidden="true"
          />
        </div>
        <span class="text-gray-700">
          {{ $t('defaults.replace') }}</span>
      </ActionDropdownItem>

      <LinkDropdownItem
        v-if="assessmentEditable"
        :href="deletePath"
        method="delete"
        :remote="true"
        :title="$t('components.observers.delete_observer')"
        :button-text="$t('defaults.delete')"
        :confirm="confirmDeletionMessage"
        :challenge="observerAssignment.user.name"
        class="flex flex-row border-t border-gray-300"
      >
        <div class="mr-4">
          <TrashIcon
            class="h-5 w-5 text-red-500"
            aria-hidden="true"
          />
        </div>
        <span class="text-red-700">{{ $t('defaults.delete') }}</span>
      </LinkDropdownItem>
    </template>
  </BaseTableActions>
</template>

<script>
import { defineComponent, toRefs, computed } from 'vue';
import { SwitchHorizontalIcon } from '@heroicons/vue/outline';
import { PencilIcon, TrashIcon } from '@heroicons/vue/solid';
import ActionDropdownItem from '@/components/generic/Dropdown/ActionDropdownItem/ActionDropdownItem.vue';
import LinkDropdownItem from '@/components/generic/Dropdown/LinkDropdownItem/LinkDropdownItem.vue';
import {
  assessmentObserverAssignmentsPath, assessmentObserverAssignmentPath,
  editAssessmentObserverAssignmentPath, editOrganisationAssessmentObserverAssignmentPath,
  organisationAssessmentObserverAssignmentPath,
} from '@/util/url-helpers';
import { useI18n } from '@/plugins/i18n';
import BaseTableActions from '@/components/generic/BaseTable/BaseTableActions/BaseTableActions.vue';

export default defineComponent({
  components: {
    PencilIcon,
    SwitchHorizontalIcon,
    TrashIcon,
    ActionDropdownItem,
    LinkDropdownItem,
    BaseTableActions,
  },
  props: {
    observerAssignment: {
      type: Object,
      required: true,
    },
    organisationId: {
      type: Number,
      required: false,
      default: null,
    },
    assessmentEditable: {
      type: Boolean,
      required: true,
    },
    isActiveAssessment: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['open-replace-dialog'],
  setup(props, { emit }) {
    const { observerAssignment } = toRefs(props);
    const { t } = useI18n();

    const editPath = props.organisationId
      ? editOrganisationAssessmentObserverAssignmentPath(
        props.organisationId,
        observerAssignment.value.assessmentId,
        observerAssignment.value,
      )
      : editAssessmentObserverAssignmentPath(
        observerAssignment.value.assessmentId,
        observerAssignment.value,
      );
    const deletePath = props.organisationId
      ? organisationAssessmentObserverAssignmentPath(
        props.organisationId,
        observerAssignment.value.assessmentId,
        observerAssignment.value,
      )
      : assessmentObserverAssignmentPath(observerAssignment.value.assessmentId, observerAssignment.value);

    const confirmDeletionMessage = computed(() => (props.isActiveAssessment
      ? t('components.observers.deleted_warning_text')
      : t('components.observers.do_you_want_to_delete', { name: props.observerAssignment.user.name })));

    const openReplaceDialog = () => {
      emit('open-replace-dialog', observerAssignment.value);
    };

    return {
      confirmDeletionMessage,
      assessmentObserverAssignmentPath,
      assessmentObserverAssignmentsPath,
      editAssessmentObserverAssignmentPath,
      openReplaceDialog,
      editPath,
      deletePath,
    };
  },
});
</script>
