<template>
  <Dropdown item-classes="right-2">
    <template #button>
      <MenuButton
        :class="[
          'rounded-md text-gray-400 focus:outline-none ring-primary-500 ring-offset-2',
          'transition-colors duration-200',
          disabled ? '' : 'hover:text-gray-500 focus:ring-2']"
        :disabled="disabled"
      >
        <DotsVerticalIcon
          class="h-6 w-6"
          aria-hidden="true"
        />
        <span class="sr-only">{{ $t('components.multi_file_upload.file_list.file.file_actions.more_actions') }}</span>
      </MenuButton>
    </template>

    <EditInOfficeActionDropdownItem
      :file="file"
      file-type="ShareableFile"
    />

    <ActionDropdownItem
      class="border-b border-gray-300"
      @click="download"
    >
      <DownloadIcon
        class="inline-block mr-4 h-5 w-5 text-gray-500"
        aria-hidden="true"
      />
      <span class="text-gray-700">{{ $t('components.multi_file_upload.file_list.file.file_actions.download') }}</span>
    </ActionDropdownItem>

    <ActionDropdownItem
      v-if="!disabled"
      @click="remove"
    >
      <TrashIcon
        class="inline-block mr-4 h-5 w-5 text-red-500"
        aria-hidden="true"
      />
      <span class="text-red-700">{{ $t('defaults.delete') }}</span>
    </ActionDropdownItem>
  </Dropdown>
</template>

<script>
import { defineComponent } from 'vue';
import { DotsVerticalIcon } from '@heroicons/vue/outline';
import { DownloadIcon, TrashIcon } from '@heroicons/vue/solid';
import { MenuButton } from '@headlessui/vue';
import Dropdown from '@/components/generic/Dropdown/Dropdown.vue';
import ActionDropdownItem from '@/components/generic/Dropdown/ActionDropdownItem/ActionDropdownItem.vue';
import EditInOfficeActionDropdownItem from '@/components/generic/EditInOffice/EditInOfficeActionDropdownItem.vue';

export default defineComponent({
  name: 'FileActions',
  components: {
    DotsVerticalIcon,
    DownloadIcon,
    TrashIcon,
    Dropdown,
    MenuButton,
    ActionDropdownItem,
    EditInOfficeActionDropdownItem,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    file: {
      type: Object,
      required: true,
    },
  },
  emits: ['download', 'remove'],
  setup(props, { emit }) {
    return {
      download(event) {
        emit('download', event);
      },
      remove(event) {
        emit('remove', event);
      },
    };
  },
});
</script>
