<template>
  <BaseButton
    v-if="assessmentIsCompleted"
    @click="activateAssessment({ sendNotifications: false })"
  >
    {{ defaultButtonText }}
  </BaseButton>
  <DropdownButton
    v-else
    @click="activateAssessment({ sendNotifications: true })"
  >
    {{ defaultButtonText }}
    <template #dropdownContent>
      <ActionDropdownItem
        @click="activateAssessment({ sendNotifications: false })"
      >
        {{ dropdownButtonText }}
      </ActionDropdownItem>
    </template>
  </DropdownButton>
</template>

<script>
import Axios from 'axios';
import ActionDropdownItem from '../generic/Dropdown/ActionDropdownItem/ActionDropdownItem.vue';

export default {
  name: 'ActivateButton',
  components: { ActionDropdownItem },
  props: {
    type: {
      type: String,
      required: true,
      validator(value) {
        return ['activate', 'ignore-warnings'].includes(value);
      },
    },
    activateAssessmentPath: {
      type: String,
      required: true,
    },
    assessmentIsCompleted: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      buttonDisabled: false,
    };
  },
  computed: {
    ignoreWarnings() {
      return this.type === 'ignore-warnings';
    },
    defaultButtonText() {
      if (this.ignoreWarnings) {
        if (this.assessmentIsCompleted) {
          return this.$t('components.assessment.activate_button.reactivate_and_ignore_warnings_without_notification');
        }

        return this.$t('components.assessment.activate_button.activate_and_ignore_warnings');
      }

      if (this.assessmentIsCompleted) {
        return this.$t('components.assessment.activate_button.reactivate_without_notification');
      }

      return this.$t('components.assessment.activate_button.activate');
    },
    dropdownButtonText() {
      if (this.ignoreWarnings) {
        return this.$t('components.assessment.activate_button.activate_and_ignore_warnings_without_notification');
      }
      return this.$t('components.assessment.activate_button.activate_without_notification');
    },
  },
  methods: {
    activateAssessment({ sendNotifications = true }) {
      this.buttonDisabled = true;
      const data = new FormData();
      data.append('send_notifications', sendNotifications);
      data.append('ignore_warnings', this.ignoreWarnings);

      Axios.put(
        this.activateAssessmentPath,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      ).then((response) => {
        Turbo.visit(response.request.responseURL);
      }).catch(({ response: { data: { error, warning } } }) => {
        if (error) {
          this.$toast.error(error);
        }
        if (warning) {
          this.$toast.warn(warning);
        }
      });
    },
  },
};
</script>
