<template>
  <Sidebar>
    <a
      v-for="item in navigation"
      :key="item.name"
      :href="item.href"
      :class="[
        item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
        'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
      ]"
    >
      <component
        :is="item.icon"
        :class="[
          item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
          'mr-3 shrink-0 h-6 w-6'
        ]"
        aria-hidden="true"
      />
      <span class="flex-1">
        {{ item.name }}
      </span>
    </a>
  </Sidebar>
</template>

<script>
import { OfficeBuildingIcon, UserCircleIcon } from '@heroicons/vue/outline';
import Sidebar from '@/components/Sidebar.vue';
import { useI18n } from 'vue-i18n';
import { editUserRegistrationPath, editTenantPath, authPath } from '@/util/url-helpers';
import { toRefs } from 'vue';

export default {
  components: {
    Sidebar,
  },
  props: {
    canEditTenant: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { canEditTenant } = toRefs(props);
    const { t } = useI18n();

    const navigation = [
      {
        name: t('components.settings_sidebar.account'),
        icon: UserCircleIcon,
        href: editUserRegistrationPath(),
        current: window.location.pathname === editUserRegistrationPath() || window.location.pathname === authPath(),
      },
    ];
    if (canEditTenant.value) {
      navigation.push({
        name: t('components.settings_sidebar.workspace'),
        icon: OfficeBuildingIcon,
        href: editTenantPath(),
        current: window.location.pathname === editTenantPath(),
      });
    }

    return {
      navigation,
    };
  },
};
</script>
