<template>
  <section class="mt-10">
    <div class="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
      <div class="mr-auto place-self-center lg:col-span-7">
        <Badge class="mb-2">
          {{ $t('components.reports_not_enabled.hero.free_beta') }}
        </Badge>
        <h1 class="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl">
          {{ $t('components.reports_not_enabled.hero.title') }}
        </h1>
        <p class="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl">
          {{ $t('components.reports_not_enabled.hero.subtitle') }}
        </p>
        <a
          :href="`mailto:info@applysia.de?subject=${$t('components.reports_not_enabled.email.subject')}
            &body=${$t('components.reports_not_enabled.email.body')}`"
          class="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white
            rounded-lg bg-primary-500 hover:bg-primary-600 focus:ring-4 focus:ring-primary-400 cursor-pointer"
        >
          {{ $t('components.reports_not_enabled.hero.button') }}
          <svg
            class="w-5 h-5 ml-2 -mr-1"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          ><path
            fill-rule="evenodd"
            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0
              110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
            clip-rule="evenodd"
          /></svg>
        </a>
      </div>
      <div class="hidden lg:mt-0 lg:col-span-5 lg:flex aspect-video">
        <VideoPlayer
          :options="videoPlayerOptions"
          class="w-full h-full"
        />
      </div>
    </div>
  </section>

  <section>
    <div class="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
      <div class="max-w-screen-md mb-8 lg:mb-16">
        <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">
          {{ $t('components.reports_not_enabled.features.title') }}
        </h2>
        <p class="text-gray-500 sm:text-xl">
          {{ $t('components.reports_not_enabled.features.subtitle') }}
        </p>
      </div>
      <div class="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
        <div>
          <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12">
            <AdjustmentsIcon class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6" />
          </div>
          <h3 class="mb-2 text-xl font-bold">
            {{ $t('components.reports_not_enabled.features.feature_1.title') }}
          </h3>
          <p class="text-gray-500">
            {{ $t('components.reports_not_enabled.features.feature_1.description') }}
          </p>
        </div>
        <div>
          <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12">
            <TemplateIcon class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6" />
          </div>
          <h3 class="mb-2 text-xl font-bold">
            {{ $t('components.reports_not_enabled.features.feature_2.title') }}
          </h3>
          <p class="text-gray-500">
            {{ $t('components.reports_not_enabled.features.feature_2.description') }}
          </p>
        </div>
        <div>
          <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12">
            <DocumentDuplicateIcon class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6" />
          </div>
          <h3 class="mb-2 text-xl font-bold">
            {{ $t('components.reports_not_enabled.features.feature_3.title') }}
          </h3>
          <p class="text-gray-500">
            {{ $t('components.reports_not_enabled.features.feature_3.description') }}
          </p>
        </div>
        <div>
          <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12">
            <SparklesIcon class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6" />
          </div>
          <h3 class="mb-2 text-xl font-bold">
            {{ $t('components.reports_not_enabled.features.feature_4.title') }}
          </h3>
          <p class="text-gray-500">
            {{ $t('components.reports_not_enabled.features.feature_4.description') }}
          </p>
        </div>
        <div>
          <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12">
            <DocumentDownloadIcon class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6" />
          </div>
          <h3 class="mb-2 text-xl font-bold">
            {{ $t('components.reports_not_enabled.features.feature_5.title') }}
          </h3>
          <p class="text-gray-500">
            {{ $t('components.reports_not_enabled.features.feature_5.description') }}
          </p>
        </div>
        <div>
          <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12">
            <PencilAltIcon class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6" />
          </div>
          <h3 class="mb-2 text-xl font-bold">
            {{ $t('components.reports_not_enabled.features.feature_6.title') }}
          </h3>
          <p class="text-gray-500">
            {{ $t('components.reports_not_enabled.features.feature_6.description') }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import VideoPlayer from '@/components/Viewers/Video/VideoPlayer.vue';
import { useI18n } from 'vue-i18n';
import {
  AdjustmentsIcon, DocumentDownloadIcon, TemplateIcon, SparklesIcon, PencilAltIcon, DocumentDuplicateIcon,
} from '@heroicons/vue/outline';

export default defineComponent({
  components: {
    VideoPlayer,
    AdjustmentsIcon,
    DocumentDownloadIcon,
    TemplateIcon,
    SparklesIcon,
    PencilAltIcon,
    DocumentDuplicateIcon,
  },
  setup() {
    const { t } = useI18n();

    const videoPlayerOptions = {
      controls: true,
      sources: [
        {
          src: t('components.reports_not_enabled.hero.video_url'),
        },
      ],
    };

    return {
      videoPlayerOptions,
    };
  },
});

</script>
