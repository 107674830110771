<template>
  <div>
    <div
      v-if="label || hint"
      :class="['flex', label ? 'justify-between' : 'justify-end']"
    >
      <label
        v-if="label"
        class="block text-sm font-medium text-gray-700"
      >
        {{ label }}
      </label>
      <span
        v-if="hint"
        class="text-sm text-gray-500"
      >
        {{ hint }}
      </span>
    </div>

    <div class="relative">
      <div
        :class="[
          'h-36 flex justify-center px-6 pt-5 pb-6 border-gray-300 rounded-md',
          label || hint ? 'mt-1' : '',
          hasFile ? '' : 'border-dashed',
          dragOver && !disabled ? 'border-4 cursor-copy' : 'border-2',
          dragOver && disabled ? '' : 'border-2',
          disabled ? 'bg-gray-100' : '',
        ]"
        @dragover.prevent="dragOver = true"
        @dragenter="dragOver = true"
        @dragleave="dragOver = false"
        @drop.prevent="onSelectFile($event.dataTransfer)"
      >
        <template v-if="hasFile">
          <div
            :class="[
              'w-full h-full rounded-md flex justify-center items-center absolute inset-0',
              'bg-black border-2 border-black opacity-0 bg-opacity-0 transition-all duration-200',
              'border-opacity-5 hover:bg-opacity-50 hover:opacity-100 space-x-4',
            ]"
            @click.stop.prevent
          >
            <button
              v-if="!disabled"
              class="
                rounded-full
                transition-colors
                duration-200
                cursor-pointer
                text-gray-300
                focus:outline-none focus:ring-1
                ring-offset-2 ring-white
                hover:text-white
              "
              @click="removeFile"
            >
              <TrashIcon class="h-6 w-6" />
              <span class="sr-only">{{
                $t("components.single_file_upload.remove_file")
              }}</span>
            </button>
            <button
              v-if="fileIsPersisted"
              class="
                rounded-full
                transition-colors
                duration-200
                cursor-pointer
                text-gray-300
                focus:outline-none focus:ring-1
                ring-offset-2 ring-white
                hover:text-white
              "
              @click="showFilePreview"
            >
              <EyeIcon class="h-6 w-6" />
              <span class="sr-only">{{
                $t("components.single_file_upload.view_file")
              }}</span>
            </button>
          </div>

          <div class="w-full h-full text-center flex flex-col">
            <div class="w-fit mx-auto flex flex-auto min-h-0 justify-center">
              <img
                v-if="preview.url"
                :src="preview.url"
                :alt="preview.text"
                class="ph-no-capture h-full rounded-md"
              >
              <DocumentIcon
                v-else
                class="h-20 w-20 text-gray-500"
                aria-hidden="true"
              />
            </div>
            <span
              v-if="preview.text"
              class="ph-no-capture mt-1 font-medium text-sm text-gray-700 text-ellipsis overflow-x-hidden shrink-0"
            >{{ preview.text }}</span>
          </div>
          <PreviewOverlay
            v-if="previewFile"
            :file="previewFile"
            @close="previewFile = null"
          />
        </template>

        <div
          v-show="!hasFile"
          class="space-y-1 flex flex-col items-center"
        >
          <svg
            class="mx-auto h-12 w-12 text-gray-400"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <!-- eslint-disable max-len -->
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <!-- eslint-enable max-len -->
          </svg>
          <div class="flex text-sm text-gray-600">
            <label
              :class="[
                'relative rounded-md font-medium',
                'focus-within:outline-none focus-within:ring-offset-2 focus-within:ring-primary-500',
                disabled
                  ? 'text-gray-700'
                  : 'cursor-pointer text-primary-600 hover:text-primary-500 focus-within:ring-2',
              ]"
            >
              <span>{{
                $t("components.single_file_upload.upload_a_file")
              }}</span>
              <input
                ref="fileInput"
                type="file"
                :accept="acceptedMimeTypes"
                :data-direct-upload-url="directUploadPath"
                :name="name"
                :disabled="disabled"
                class="sr-only"
                @change="onSelectFile($event.target)"
              >
            </label>
            <p class="pl-1">
              {{ $t("components.single_file_upload.or_drag_and_drop") }}
            </p>
          </div>
          <p class="text-xs text-gray-500">
            {{ fileTypesAndSizeLimit }}
          </p>
        </div>

        <input
          v-if="fileIsDestroyed && !disabled"
          type="hidden"
          :name="destroyName"
          value="1"
        >
      </div>
    </div>

    <p
      v-if="error"
      class="mt-2 text-sm text-red-600"
    >
      {{ error }}
    </p>
  </div>
</template>

<script>
import PreviewOverlay from '@/components/PreviewOverlay/PreviewOverlay.vue';
import { useI18n } from '@/plugins/i18n';
import { useNotifications } from '@/plugins/toast';
import { directUploadPath } from '@/util/url-helpers';
import {
  DocumentIcon,
  EyeIcon,
  TrashIcon,
} from '@heroicons/vue/outline';
import { computed, ref, toRefs, watch } from 'vue';

export default {
  name: 'SingleFileUpload',
  components: {
    DocumentIcon,
    TrashIcon,
    EyeIcon,
    PreviewOverlay,
  },
  props: {
    /**
     * For the input field, e.g., application/pdf
     */
    acceptedMimeTypes: {
      type: String,
      required: true,
    },
    /**
     * In human readable format, e.g., PDF
     */
    acceptedFileTypes: {
      type: String,
      required: true,
    },
    /**
     * The maximum file size as a string, e.g., 10MB.
     */
    sizeLimit: {
      type: String,
      default: '',
    },
    file: {
      type: Object,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    destroyName: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },

    error: {
      type: String,
      default: '',
    },

    customDirectUploadPath: {
      type: String,
      required: false,
      default: null,
    },
  },
  emits: ['selected', 'removed'],
  setup(props, { emit }) {
    const { warn } = useNotifications();
    const { acceptedFileTypes, sizeLimit, customDirectUploadPath } = toRefs(props);
    const { t } = useI18n();

    const fileInput = ref(null);
    const file = ref(props.file);

    const preview = ref({ url: file.value?.previewUrl, text: file.value?.filename });
    const fileIsPersisted = computed(() => {
      if (preview.value.url === null) {
        return false;
      }

      return !preview.value.url?.startsWith('data:');
    });

    const updatePreview = () => {
      if (file.value) {
        if (/^image/g.exec(file.value.type)) {
          const reader = new FileReader();
          reader.onload = ({ target }) => {
            preview.value.url = target.result;
          };
          reader.readAsDataURL(file.value);
        } else {
          preview.value.url = null;
        }
        preview.value.text = file.value.name;
      } else {
        preview.value.url = null;
        preview.value.text = null;
      }
    };

    watch(() => props.file, (newValue) => {
      file.value = newValue;
      preview.value = { url: file.value?.previewUrl, text: file.value?.filename };
    });

    const dragOver = ref(false);
    const fileIsDestroyed = ref(false);
    const onSelectFile = ({ files }) => {
      dragOver.value = false;
      if (!files.length) return;

      if (fileInput.value.files !== files) {
        fileInput.value.files = files;
      }

      const fileToUpload = files[0];
      if (fileToUpload.size <= 0) {
        warn(t('components.multi_file_upload.empty_file'));
        updatePreview();
      } else {
        file.value = fileToUpload;
        fileIsDestroyed.value = false;
        updatePreview();
        emit('selected', file.value);
      }
    };

    const hasFile = computed(
      () => file.value,
    );

    const removeFile = () => {
      if (hasFile.value) fileIsDestroyed.value = true;
      file.value = null;
      fileInput.value.files = new DataTransfer().files;
      updatePreview();
      emit('removed');
    };

    const previewFile = ref(null);
    const showFilePreview = () => {
      previewFile.value = {
        url: file.value?.url,
        type: file.value?.type,
        text: file.value?.name,
        filename: file.value?.filename,
      };
    };

    const fileTypesAndSizeLimit = computed(() => {
      const sizeLimitString = sizeLimit.value
        ? t('components.single_file_upload.up_to', {
          size_limit: sizeLimit.value,
        })
        : '';
      return `${acceptedFileTypes.value} ${sizeLimitString}`;
    });

    return {
      hasFile,
      fileIsPersisted,
      dragOver,
      fileInput,
      onSelectFile,
      preview,
      previewFile,

      fileIsDestroyed,
      removeFile,
      showFilePreview,

      fileTypesAndSizeLimit,
      directUploadPath: computed(() => customDirectUploadPath.value || directUploadPath()),
    };
  },
};
</script>
