<template>
  <div class="mb-6">
    <PageHeading>
      {{ title }}
      <template #actions>
        <SaveButton
          :is-disabled="!editable"
          :is-loading="submitting"
          form="schedule-form"
        >
          {{ $t('defaults.save') }}
        </SaveButton>
      </template>
    </PageHeading>
  </div>
  <form
    id="schedule-form"
    :action="actionUrl"
    accept-charset="UTF-8"

    method="post"
    enctype="multipart/form-data"
    @submit="submitting = true"
  >
    <input
      type="hidden"
      name="_method"
      value="patch"
    >
    <input
      type="hidden"
      name="authenticity_token"
      :value="$csrfToken"
    >
    <TwoColumnCard
      :header="$t('components.schedule_plan.categories.schedule.header')"
      :helptext="$t('components.schedule_plan.categories.schedule.helptext')"
    >
      <SingleFileUpload
        :disabled="!editable"
        accepted-mime-types="application/pdf, image/jpeg, image/png"
        accepted-file-types="PDF / PNG / JPG"
        :file="file"
        name="schedule[file]"
        destroy-name="destroy_schedule"
      />
    </TwoColumnCard>
  </form>
</template>

<script>
import { ref } from 'vue';
import { assessmentSchedulePath, organisationAssessmentSchedulePath } from '@/util/url-helpers';
import SingleFileUpload from '@/components/generic/SingleFileUpload/SingleFileUpload.vue';

export default {
  components: {
    SingleFileUpload,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    previewUrl: {
      type: String,
      required: true,
    },
    assessmentId: {
      type: String,
      required: true,
    },
    organisationId: {
      type: Number,
      required: false,
      default: null,
    },
    file: {
      type: Object,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const actionUrl = props.organisationId
      ? organisationAssessmentSchedulePath(props.organisationId, props.assessmentId)
      : assessmentSchedulePath(props.assessmentId);
    const submitting = ref(false);

    return {
      actionUrl,
      submitting,
    };
  },
};
</script>
