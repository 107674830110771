<template>
  <ConfirmDialogModal
    :open="open"
    :message="message"
    @confirm="confirm"
    @close="close"
  />
</template>

<script>
import { ref } from 'vue';
import ConfirmDialogModal from '@/components/generic/ConfirmDialogModal/ConfirmDialogModal.vue';

export default {
  name: 'TurboConfirmAdapter',
  components: {
    ConfirmDialogModal,
  },
  setup() {
    const open = ref(false);
    const message = ref('');
    const element = ref(null);

    const showDialog = (confirmMessage, sourceElement) => {
      open.value = true;

      element.value = sourceElement;
      message.value = confirmMessage;

      return false;
    };

    Turbo.setConfirmMethod(showDialog);

    const confirm = () => {
      element.value.removeAttribute('data-turbo-confirm');
      const csrfToken = document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content');

      if (document.body.contains(element.value)) {
        element.value.insertAdjacentHTML(
          'beforeend',
          `<input type="hidden" name="authenticity_token" value="${csrfToken}">`,
        );
        element.value.submit();
      } else {
        document.querySelector('body').appendChild(element.value);
        element.value.insertAdjacentHTML(
          'beforeend',
          `<input type="hidden" name="authenticity_token" value="${csrfToken}">`,
        );
        element.value.submit();
        element.value.remove();
      }
    };

    const close = () => {
      open.value = false;
    };

    return {
      open,
      message,
      confirm,
      close,
    };
  },
};
</script>
