<template>
  <component
    :is="as"
    :type="type"
    :name="name"
    :href="link"
    class="inline-flex items-center justify-center px-4 py-2 border
          text-sm font-medium
          focus:outline-none focus:ring-2 base-button whitespace-nowrap appearance-none"
    :class="classes"
    :disabled="isDisabled || isLoading"
    @click="$emit('click', $event)"
  >
    <div class="pointer-events-none">
      <div class="grid">
        <div
          class="overlap flex items-center"
          :class="{'opacity-0': isLoading}"
        >
          <span v-if="!leadingIcon"><slot /></span>
          <div
            v-if="icon"
            class="h-5 w-5"
            :class="[{'mr-2.5': hasText && leadingIcon}, {'ml-2.5': hasText && !leadingIcon}, iconStyle]"
          >
            <Heroicon
              :icon="icon"
            />
          </div>
          <span v-if="leadingIcon"><slot /></span>
        </div>

        <span
          v-if="isLoading"
          class="overlap -mb-1 flex items-center justify-center space-x-1"
        >
          <div
            :class="loadingClasses"
            class="p-1 w-3 h-3 rounded-full animate-bounce loading-circle-1 "
          />
          <div
            :class="loadingClasses"
            class="p-1 w-3 h-3 rounded-full animate-bounce loading-circle-2"
          />
          <div
            :class="loadingClasses"
            class="p-1 w-3 h-3 rounded-full animate-bounce loading-circle-3"
          />
        </span>
      </div>
    </div>
  </component>
</template>

<script>
import { defineComponent, toRefs, computed } from 'vue';
import { MenuButton } from '@headlessui/vue';
import Heroicon from '@/components/Heroicon.vue';

const classesPrimary = 'text-white focus:ring-primary-400 border-transparent rounded-md shadow-sm focus:ring-offset-2';
const classesSecondary = `focus:outline-none focus:ring-2
 focus:ring-primary-400 border-gray-300 rounded-md shadow-sm focus:ring-offset-2`;
const classesPrimaryActive = 'bg-primary-500 hover:bg-primary-600';
const classesPrimaryDisabled = 'bg-primary-300 cursor-not-allowed';
const classesSecondaryActive = 'bg-white hover:bg-gray-50 text-gray-700';
const classesSecondaryDisabled = 'bg-coolGray-100 cursor-not-allowed text-gray-500';

const loadingClassesPrimary = 'bg-white';
const loadingClassesSecondary = 'bg-gray-500';

const classesLightColors = `focus:ring-primary-400 border-transparent
 rounded-md shadow-sm focus:ring-offset-2`;
const classesLightColorsActive = 'bg-primary-50 text-primary-700 hover:bg-primary-100';
const classesLightColorsDisabled = 'bg-gray-300 text-gray-700 cursor-not-allowed';
const loadingClassesLightColors = 'bg-gray-500';

export default defineComponent({
  name: 'BaseButton',
  components: {
    Heroicon,
    MenuButton,
  },
  props: {
    as: {
      type: String,
      default: 'button',
    },
    isPrimary: {
      type: Boolean,
      default: true,
    },
    lightColors: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    iconStyle: {
      type: String,
      default: null,
    },
    leadingIcon: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'button',
    },
    name: {
      type: String,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
      default: null,
    },
    qaClass: {
      type: String,
      default: '',
    },
  },
  emits: ['click'],
  setup(props, { slots }) {
    const {
      isPrimary, isDisabled, isLoading, lightColors, qaClass,
    } = toRefs(props);
    const classes = computed(() => ({
      [classesPrimary]: isPrimary.value && !lightColors.value,
      [classesSecondary]: !isPrimary.value && !lightColors.value,
      [classesLightColors]: lightColors.value,
      [classesPrimaryDisabled]: (isDisabled.value || isLoading.value) && isPrimary.value && !lightColors.value,
      [classesPrimaryActive]: !isDisabled.value && !isLoading.value && isPrimary.value && !lightColors.value,
      [classesSecondaryDisabled]: (isDisabled.value || isLoading.value) && !isPrimary.value && !lightColors.value,
      [classesSecondaryActive]: !isDisabled.value && !isLoading.value && !isPrimary.value && !lightColors.value,
      [classesLightColorsDisabled]: (isDisabled.value || isLoading.value) && lightColors.value,
      [classesLightColorsActive]: !isDisabled.value && !isLoading.value && lightColors.value,
      [`qa-${qaClass?.value}`]: qaClass?.value !== '',
    }));

    const loadingClasses = computed(() => ({
      [loadingClassesPrimary]: isPrimary.value && !lightColors.value,
      [loadingClassesSecondary]: !isPrimary.value && !lightColors.value,
      [loadingClassesLightColors]: lightColors.value,
    }));

    const hasText = computed(() => !!slots.default);

    const link = props.as === 'a' ? props.href : null;

    return { classes, loadingClasses, hasText, link };
  },
});
</script>

<style lang="scss" scoped>
.overlap {
  grid-area: 1 / 1;
}
.loading-circle-2 {
  animation-delay: 0.1s;
}
.loading-circle-3 {
  animation-delay: 0.2s;
}

.plain {
  @apply shadow-none focus:ring-offset-0 #{!important};
}
</style>
