<template>
  <chart-card
    :title="$t('components.insights.show.competencies')"
    subtitle="Benchmark"
  >
    <div
      :style="{height: `${chartHeight}px`}"
      class="relative"
    >
      <canvas
        ref="chart"
      />
    </div>
  </chart-card>
</template>
<script>
import { Chart } from 'chart.js/auto';

import ChartCard from '@/components/Insights/ChartCard.vue';

export default {
  name: 'CompetenciesBenchmark',
  components: {
    ChartCard,
  },
  props: {
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 5,
    },
    competencies: {
      type: Array,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    chartHeight() {
      return Math.max(this.competencies.length * 100, 400);
    },
  },
  mounted() {
    const chartElement = this.$refs.chart;

    const defaultColors = [
      '#3366CC', '#DC3912', '#FF9900', '#109618', '#990099', '#3B3EAC', '#0099C6',
      '#DD4477', '#66AA00', '#B82E2E', '#316395', '#994499', '#22AA99', '#AAAA11',
      '#6633CC', '#E67300', '#8B0707', '#329262', '#5574A6', '#651067',
    ];

    const labels = [];
    const meanData = [];
    const lowerStdData = [];
    const higherStdData = [];

    this.competencies.forEach((competency) => {
      labels.push(competency.name);

      const data = this.data.find((row) => row.competencyName === competency.name);

      meanData.push(data?.avg);
      lowerStdData.push(data?.avgMinusStddev);
      higherStdData.push(data?.avgPlusStddev);
    });

    const chartData = {
      labels,
      datasets: [
        {
          axis: 'y',
          borderColor: defaultColors[0],
          backgroundColor: defaultColors[0],
          data: meanData,
          label: this.$t('components.insights.show.mean'),
        },
        {
          axis: 'y',
          borderDash: [5, 5],
          fill: false,
          data: lowerStdData,
          label: this.$t('components.insights.show.standard_deviation'),
        },
        {
          axis: 'y',
          borderDash: [5, 5],
          fill: 1,
          data: higherStdData,
        },
      ],
    };

    const config = {
      type: 'line',
      data: chartData,
      options: {
        locale: 'en',
        maintainAspectRatio: false,
        indexAxis: 'y',
        tension: 0.25,
        scales: {
          y: {
            ticks: {
              autoSkip: false,
              font: {
                size: 14,
              },
              callback(value) {
                const label = this.getLabelForValue(value);
                if (label.length > 20) {
                  return `${this.getLabelForValue(value).substring(0, 20)}...`;
                }
                return this.getLabelForValue(value);
              },
            },
            title: {
              font: {
                size: 14,
              },
              color: '#333',
            },
            grid: {},
            offset: true,
          },
          x: {
            min: this.min,
            max: this.max,
            grid: {
              drawOnChartArea: false,
            },
            title: {
              font: {
                size: 14,
              },
              color: '#333',
            },
            time: {},
            ticks: {
              font: {
                size: 14,
              },
            },
            offset: 2,
          },
        },
        animation: false,
        plugins: {
          legend: {
            labels: {
              filter(item, _) {
                return item.text !== undefined;
              },
            },
            position: 'bottom',
          },
          tooltip: {
            displayColors: false,
            callbacks: {
              label: (tooltipItem, _) => {
                const compData = this.data.find((row) => row.competencyName === tooltipItem.label);

                return [`${this.$t('components.insights.show.mean')}: ${compData?.avg} `,
                  `${this.$t('components.insights.show.standard_deviation')}: ${compData?.stddev}`];
              },
            },
          },
          title: {
            font: {
              size: 20,
            },
            color: '#333',
          },
        },
        interaction: {},
      },
    };

    // eslint-disable-next-line no-new
    const chart = new Chart(
      chartElement,
      config,
    );

    chart.legend.options.onClick = (e, legendItem) => {
      const index = legendItem.datasetIndex;
      const meta = chart.getDatasetMeta(index);

      // Toggle the visibility of the clicked dataset
      meta.hidden = meta.hidden === null ? !chart.data.datasets[index].hidden : null;

      // If Dataset 2 is hidden, hide Dataset 3 as well
      if (index === 1) {
        const meta2 = chart.getDatasetMeta(2);
        meta2.hidden = meta.hidden;
      }

      // Update the chart
      chart.update();
    };
  },
};
</script>
