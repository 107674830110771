<template>
  <div class="min-h-full flex flex-col justify-center bg-gray-50">
    <div class="space-y-8 overflow-auto py-4">
      <div
        class="sm:mx-auto sm:w-full sm:max-w-md text-center object-center
             content-center justify-center space-y-6 px-5"
      >
        <img
          class="mx-auto h-12 w-auto"
          :src="logoUrl"
          alt="Applysia"
        >
        <div>
          <h2
            class="text-gray-900 text-3xl leading-9 font-extrabold block"
          >
            {{ $t('components.session.create_header') }}
          </h2>
          <p
            class="text-gray-500 text-sm leading-5 font-normal mt-2"
          >
            {{ $t('components.session.create_header_helptext') }}
          </p>
        </div>
      </div>
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <Card
          class="py-8 px-5 sm:px-10"
        >
          <form
            id="invite-form"
            :action="actionUrl"
            accept-charset="UTF-8"
            method="post"
            enctype="multipart/form-data"
            data-turbo="false"
          >
            <input
              type="hidden"
              name="_method"
              value="put"
            >
            <input
              type="hidden"
              name="authenticity_token"
              :value="$csrfToken"
            >
            <input
              type="hidden"
              name="user[invitation_token]"
              :value="user.invitationToken"
            >
            <input
              type="hidden"
              name="commit"
              :value="'Set my password'"
            >
            <input
              type="hidden"
              name="user[time_zone]"
              :value="initialTimezone.value"
            >
            <div class="space-y-6">
              <template v-if="passwordAuthEnabled">
                <base-input
                  v-model="password"
                  name="user[password]"
                  autocomplete="new-password"
                  :label="$t('components.session.fields.password')"
                  :type="'password'"
                  :error="getError('password')"
                />
                <password-requirements v-model="password" />
                <base-input
                  name="user[password_confirmation]"
                  autocomplete="new-password"
                  :label="$t('components.session.fields.password_confirmation')"
                  :type="'password'"
                  :error="getError('password_confirmation')"
                />
              </template>
              <PolicyConsent
                v-model:privacy-policy-consent="privacyPolicyConsent"
                v-model:analytics-policy-consent="analyticsPolicyConsent"
                privacy-field-name="user[privacy_policy_consent]"
                analytics-field-name="user[analytics_policy_consent]"
                @update:privacy-policy-enabled="privacyPolicyConsent = $event"
                @update:analytics-policy-enabled="analyticsPolicyConsent = $event"
              />
              <base-button
                class="btn w-full"
                type="submit"
                :is-disabled="!privacyPolicyConsent"
                :is-loading="loading"
                form="invite-form"
              >
                {{ $t('components.session.buttons.create_account') }}
              </base-button>
            </div>
          </form>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import { userInvitationPath } from '@/util/url-helpers';
import { defineComponent, ref } from 'vue';
import Card from '@/components/generic/Card/Card.vue';
import BaseInput from '@/components/generic/BaseInput/BaseInput.vue';
import BaseButton from '@/components/generic/BaseButton/BaseButton.vue';
import PolicyConsent from '@/components/generic//PolicyConsent/PolicyConsent.vue';
import PasswordRequirements from '@/components/generic/PasswordRequirements/PasswordRequirements.vue';
import { useTimezoneOptions, getCurrentTimeZone } from '@/composables/timezoneOptions';

export default defineComponent({
  name: 'InvitationForm',
  components: {
    Card,
    BaseInput,
    BaseButton,
    PolicyConsent,
    PasswordRequirements,
  },
  props: {
    userData: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    availableTimeZones: {
      type: Array,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    logoUrl: {
      type: String,
      default: '/applysia-logo.png',
    },
    passwordAuthEnabled: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const user = { ...props.userData };

    const actionUrl = userInvitationPath();
    const loading = ref(false);

    const { initialTimezone } = useTimezoneOptions(props.availableTimeZones, getCurrentTimeZone());

    const privacyPolicyConsent = ref(user.privacyPolicyConsent);
    const analyticsPolicyConsent = ref(user.analyticsPolicyConsent);

    const password = ref('');
    const getError = (...keys) => props.errors[keys.join('.')]?.[0];

    return {
      user,
      getError,
      actionUrl,
      loading,
      initialTimezone,
      privacyPolicyConsent,
      analyticsPolicyConsent,
      password,
    };
  },
});
</script>
