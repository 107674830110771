<template>
  <Listbox
    v-model="selectedInternal"
    as="div"
  >
    <input
      type="hidden"
      :name="name"
      :value="selectedValue"
      :disabled="disabled"
    >
    <div class="flex justify-between">
      <ListboxLabel
        v-if="label"
        class="block text-sm font-medium text-gray-700"
      >
        {{ label }}
      </ListboxLabel>
      <span class="text-sm text-gray-500">{{ hint }}</span>
    </div>
    <div class="mt-1 relative">
      <ListboxButton
        class="listbox-button relative w-full border rounded-md shadow-sm
        pl-3 pr-10 py-2 text-left cursor-default focus:outline-none "
        :class="{
          'border-red-300 focus:ring-red-500 focus:border-red-500 text-red-900': error && !disabled,
          'border-gray-300 focus:ring-primary-500 focus:border-primary-500': !error && !disabled,
          'focus:ring-1': !disabled,
          'bg-gray-100': disabled,
        }"
      >
        <span class="w-full flex truncate">
          <span class="truncate text-sm">{{ selectedLabel }}</span>
          <span
            v-if="secondaryLabelProp"
            class="ml-2 truncate text-gray-500 text-sm"
          >
            {{ selectedSecondaryLabel }}
          </span>
        </span>
        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <SelectorIcon
            class="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </span>
      </ListboxButton>

      <div
        v-if="!disabled"
      >
        <SelectListContent
          :options="options"
          :value-prop="valueProp"
          :label-prop="labelProp"
          :secondary-label-prop="secondaryLabelProp"
        />
      </div>
    </div>
    <span
      v-if="error"
      class="mt-2 text-sm text-red-600"
    >
      {{ error }}
    </span>
  </Listbox>
</template>

<script>
import { Listbox, ListboxButton, ListboxLabel } from '@headlessui/vue';
import { SelectorIcon } from '@heroicons/vue/outline';
import { computed, ref, toRefs, watch } from 'vue';
import SelectListContent from './SelectListContent/SelectListContent.vue';

export default {
  components: {
    Listbox,
    ListboxButton,
    ListboxLabel,
    SelectorIcon,
    SelectListContent,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    selected: {
      type: Object,
      default: null,
    },
    hint: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: null,
    },
    valueProp: {
      type: String,
      default: 'value',
    },
    labelProp: {
      type: String,
      default: 'label',
    },
    secondaryLabelProp: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: 'select',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:selected'],
  setup(props, { emit }) {
    const { selected } = toRefs(props);

    const selectedInternal = ref(selected.value);

    const selectedValue = computed(() => selectedInternal.value?.[`${props.valueProp}`]);
    const selectedLabel = computed(() => selectedInternal.value?.[`${props.labelProp}`]);
    const selectedSecondaryLabel = computed(() => selectedInternal.value?.[`${props.secondaryLabelProp}`]);

    watch(selected, (newValue) => {
      selectedInternal.value = newValue;
    });

    watch(selectedInternal, (newValue) => {
      emit('update:selected', newValue);
    }, { immediate: true });

    return {
      selectedInternal,
      selectedValue,
      selectedLabel,
      selectedSecondaryLabel,
    };
  },
};
</script>

<style scoped>
  .listbox-button {
    min-height: 37px
  }
</style>
