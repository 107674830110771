<template>
  <transition
    enter-from-class="opacity-0 translate-x-full"
    enter-to-class="opacity-1 translate-x-0"
    leave-from-class="opacity-1 translate-x-0"
    leave-to-class="opacity-0 translate-x-full"
    enter-active-class="transition-all duration-300 ease-out"
    leave-active-class="transition-all duration-300 ease-in"
  >
    <div
      v-show="isActive"
      ref="toast"
      role="alert"
      class="self-end relative w-100 rounded-md my-4 shadow-xl bg-white pointer-events-auto"
      :class="qaClass"
      @mouseover="pause"
      @mouseleave="resume"
    >
      <div
        class="rounded-sm top-0 left-0 transition-all"
        :class="progressBarClass"
        :style="progressBarStyle"
      />

      <button
        class="absolute mt-2 mr-2 top-0 right-0 text-gray-500 hover:text-gray-700 focus:outline-none transition-colors
          duration-200"
        @click="close"
      >
        <Heroicon
          icon="XIcon"
          aria-hidden="true"
          class="w-5 h-5"
        />
        <span class="sr-only">{{ t('defaults.close') }}</span>
      </button>

      <div class="grid grid-cols-8 gap-2 p-4">
        <div class="col-span-1">
          <Heroicon
            :icon="icon"
            :class="['h-5 w-5 ml-1 mt-0.5', iconClass]"
            aria-hidden="true"
          />
        </div>

        <div class="col-span-7">
          <p class="font-semibold mb-1 text-gray-700">
            {{ t(`components.toast_notification.${type}`) }}
          </p>
          <!-- eslint-disable vue/no-v-html -->
          <p
            class="text-gray-500"
            v-html="message"
          />
          <!-- eslint-enable vue/no-v-html -->
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Heroicon from '@/components/Heroicon.vue';
import EventBus from '@/util/event-bus';
import Timer from '@/util/Timer';
import removeElement from '@/util/remove-element';
import { useI18n } from '@/plugins/i18n';

const DEFAULT_TIMEOUT = 5000;
const Types = Object.freeze({
  SUCCESS: 'success',
  ERROR: 'error',
  WARN: 'warn',
  NOTICE: 'notice',
});

export default {
  name: 'ToastNotification',
  components: {
    Heroicon,
  },
  props: {
    message: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: Types.NOTICE,
      validator(value) {
        return Object.values(Types).includes(value);
      },
    },
    delay: {
      type: Number,
      required: false,
      default: DEFAULT_TIMEOUT,
    },
  },
  setup() {
    const { t } = useI18n();
    return { t };
  },
  data() {
    return {
      isActive: false,
      timer: null,
    };
  },
  computed: {
    qaClass() {
      switch (this.type) {
        case Types.SUCCESS:
          return 'qa-toast-success';
        case Types.ERROR:
          return 'qa-toast-error';
        case Types.WARN:
          return 'qa-toast-warn';
        case Types.NOTICE:
        default:
          return 'qa-toast-notice';
      }
    },
    icon() {
      switch (this.type) {
        case Types.SUCCESS:
          return 'CheckCircleIcon';
        case Types.ERROR:
          return 'XCircleIcon';
        case Types.WARN:
          return 'ExclamationCircleIcon';
        case Types.NOTICE:
        default:
          return 'InformationCircleIcon';
      }
    },
    iconClass() {
      switch (this.type) {
        case Types.SUCCESS:
          return 'text-green-500';
        case Types.ERROR:
          return 'text-red-500';
        case Types.WARN:
          return 'text-yellow-500';
        case Types.NOTICE:
        default:
          return 'text-blue-500';
      }
    },

    progress() {
      return this.timer?.progress || 1;
    },
    progressBarClass() {
      switch (this.type) {
        case Types.SUCCESS:
          return 'bg-green-500';
        case Types.ERROR:
          return 'bg-red-500';
        case Types.WARN:
          return 'bg-yellow-500';
        case Types.NOTICE:
        default:
          return 'bg-blue-500';
      }
    },
    progressBarStyle() {
      return {
        width: `${this.progress * 100}%`,
        height: '2px',
      };
    },
  },
  mounted() {
    EventBus.on('clear-toast-notification', this.close);
    this.isActive = true;
    this.timer = new Timer({
      delay: this.delay,
      onComplete: this.close,
    });
    this.timer.start();
  },
  beforeUnmount() {
    EventBus.off('clear-toast-notification', this.close);
    this.timer.stop();
  },
  methods: {
    close() {
      this.isActive = false;
      setTimeout(() => { removeElement(this.$refs.toast); }, 1000);
    },
    pause() {
      this.timer?.pause();
    },
    resume() {
      this.timer?.resume();
    },
  },
};
</script>
