<template>
  <BaseTable
    :columns="columns"
    :records="tenants"
    :pages="pages"
  >
    <template #default="{ record: tenant }">
      <td class="ph-no-capture px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        {{ tenant.name }}
      </td>
      <td class="ph-no-capture px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-700">
        {{ tenant.subdomain }}
      </td>
      <td class="px-6 py-4 whitespace-nowrap">
        <Badge
          v-if="tenant.isDeleted"
          color="red"
        >
          {{ $t('components.tenants.tenants_table.will_be_deleted') }}
        </Badge>
      </td>
      <TenantActions
        :tenant="tenant"
      />
    </template>
  </BaseTable>
</template>

<script>
import BaseTable from '@/components/generic/BaseTable/BaseTable.vue';
import Badge from '@/components/generic/Badge/Badge.vue';
import { useI18n } from '@/plugins/i18n';
import TenantActions from './TenantActions/TenantActions.vue';

export default {
  components: {
    BaseTable,
    Badge,
    TenantActions,
  },
  props: {
    tenants: {
      type: Array,
      required: true,
    },
    pages: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();

    const columns = [
      {
        key: 'name',
        name: t('activerecord.attributes.tenant.name'),
        sortable: true,
      },
      {
        key: 'subdomain',
        name: t('activerecord.attributes.tenant.subdomain'),
        sortable: true,
      },
      {
        key: 'will_be_deleted',
        name: '',
        hidden: true,
      },
      {
        key: 'actions',
        name: t('defaults.manage'),
        hidden: true,
      },
    ];

    return {
      columns,
    };
  },
};
</script>
