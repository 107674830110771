<template>
  <ActionDropdownItem
    :disabled="isDisabled"
    :class="['flex flex-row', isDisabled ? '' : 'cursor-pointer']"
    @click="openFileExplorer"
  >
    <div class="mr-4">
      <SwitchHorizontalIcon
        :class="['h-5 w-5', isDisabled ? 'text-gray-300' : 'text-gray-500']"
        aria-hidden="true"
      />
    </div>
    <span
      :class="isDisabled ? 'text-gray-300' : 'text-gray-700'"
    >
      {{ $t('defaults.replace') }}
    </span>
    <input
      ref="filesInputRef"
      type="file"
      :accept="acceptedMimeTypes"
      :disabled="isDisabled"
      class="hidden"
      @change="onFileUpload($event.target)"
    >
  </ActionDropdownItem>
</template>
<script>
import ActionDropdownItem from '@/components/generic/Dropdown/ActionDropdownItem/ActionDropdownItem.vue';
import { SwitchHorizontalIcon } from '@heroicons/vue/solid';
import { defineComponent, ref } from 'vue';
import { useReportTemplatesStore } from './stores/reportTemplatesStore';

export default defineComponent({
  name: 'ReplaceReportTemplateDocumentDropdownItem',
  components: {
    ActionDropdownItem,
    SwitchHorizontalIcon,
  },
  props: {
    reportTemplate: {
      type: Object,
      required: false,
      default: () => null,
    },
    isDisabled: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const store = useReportTemplatesStore();

    const filesInputRef = ref(null);
    const acceptedMimeTypes = [
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    ];

    const openFileExplorer = () => {
      filesInputRef.value.click();
    };

    const onFileUpload = async ({ files }) => {
      if (files.length) {
        const file = files[0];
        store.setReplacementDocument({
          reportTemplate: props.reportTemplate,
          replacementDocument: {
            filename: file.name, content_type: file.type, signed_id: file.signed_id, isPending: true,
          },
        });

        const uploadedFile = (await store.uploadSelectedFile([file]))[0];

        if (uploadedFile) {
          store.setReplacementDocument({ reportTemplate: props.reportTemplate, replacementDocument: uploadedFile });
        }
      }
    };

    return {
      acceptedMimeTypes,
      filesInputRef,
      openFileExplorer,
      onFileUpload,
    };
  },
});
</script>
