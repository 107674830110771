<template>
  <div class="tile bg-white rounded-lg overflow-hidden shadow flex-col justify-start items-start inline-flex">
    <div class="relative overflow-hidden max-w-sm min-w-md min-h-fit tile divide-y divide-gray-200">
      <div
        class="relative h-[90%] overflow-hidden bg-white"
        :class="selectable? 'cursor-pointer' : ''"
        @click="selectable? $emit('select', previewObject) : ''"
      >
        <slot name="statusBanner" />
        <div
          v-if="previewObject.previewUrl"
          class="w-full min-h-full px-4 pt-4 flex items-center"
        >
          <img
            :src="previewObject.previewUrl"
            class="w-full object-top "
            onerror="this.style.display='none'"
          >
        </div>
        <div
          v-else
          class="w-full h-full flex bg-blue-50 items-center justify-center"
        >
          <DocumentTextIcon class="text-gray-400 h-14 w-14" />
        </div>
        <div
          v-if="selectable"
          class="hover:bg-gray-900 hover:opacity-5 absolute inset-0"
        />
      </div>
      <div class="h-[10%] pl-5 pr-3 bg-white rounded-b-lg flex justify-between items-center">
        <p class="pr-1 line-clamp-2 break-all text-left text-sm leading-5  text-gray-700">
          {{ previewObject.name }}
        </p>
        <div class="cursor-pointer visible z-10">
          <slot name="previewTileActions" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DocumentTextIcon } from '@heroicons/vue/outline';

export default {
  name: 'PreviewTile',
  components: {
    DocumentTextIcon,
  },
  props: {
    previewObject: {
      type: Object,
      required: true,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['select'],
};
</script>
<style scoped>
.tile {
  height: 384px;
  width: 370px;
}

.overlay:hover {
  background-color: rgba(24, 24, 27, 0.05);
}
</style>
